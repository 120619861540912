'use client'
export {
    ArrowLeft,
    ArrowRight,
    ArrowsOutSimple,
    Bank,
    CalendarX,
    CalendarBlank,
    CaretDown,
    CaretLeft,
    CaretRight,
    CaretUp,
    Chat,
    Check,
    CheckCircle,
    Clock,
    CreditCard,
    CurrencyCircleDollar,
    CurrencyDollar,
    DownloadSimple,
    EnvelopeSimple,
    Eye,
    FacebookLogo,
    Faders,
    File,
    Image,
    InstagramLogo,
    Lightning,
    Link,
    LinkedinLogo,
    List,
    ListBullets,
    MagnifyingGlass,
    MagnifyingGlassPlus,
    MapPin,
    Minus,
    Package,
    PaperPlaneTilt,
    PencilSimple,
    Phone,
    PhoneCall,
    PinterestLogo,
    Plus,
    Question,
    Shield,
    ShoppingCart,
    Stack,
    Star,
    Storefront,
    TextAlignCenter,
    TextAlignJustify,
    TextAlignLeft,
    Trash,
    TrashSimple,
    Truck,
    Upload,
    UserCircle,
    Wrench,
    X
} from '@phosphor-icons/react'
