import { Product } from "@licommon/hooks/compare/useCompare";
import { IN_BROWSER } from "@licommon/utils/session";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const COMPARE_ITEMS_KEY = 'compareItems'

type CompareSummary = {
  products: Product[] | []
}

const initialState = {
  products: IN_BROWSER ? JSON.parse(localStorage.getItem(COMPARE_ITEMS_KEY) || '[]') : [],
} as CompareSummary

const compare = createSlice({
  name: 'compare',
  initialState,
  reducers: {
    setCompareProducts: (state, action: PayloadAction<Product[] | []>) => {
      state.products = action.payload
      if (IN_BROWSER) {
        localStorage.setItem(COMPARE_ITEMS_KEY, JSON.stringify(action.payload))
      }
    },
  },
})

export const { setCompareProducts } = compare.actions

export default compare.reducer