export const decodeCssString = (css: string) => {
  if (!css){
    return {};
  }
  const properties: any = {};

  // Use a regular expression to match property-value pairs
  const pattern = /([^:;]+)\s*:\s*([^;]+);/g;
  let match;

  while ((match = pattern.exec(css)) !== null) {
    properties[match[1].trim().replace(/-([a-z])/g, (_, letter) => letter.toUpperCase())] = match[2].trim();
  }

  return properties;
};