'use client'
import { Transition } from '@headlessui/react'
import { clsx } from '@licommon/utils/clsx'
import { getPortalType } from '@licommon/utils/portal'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { CaretDown } from './Icons'

interface AccordionProps {
    title: string | JSX.Element
    children?: ReactNode
    open: boolean
    outerDivClassName?: string
    buttonClassName?: string
    headingClassName?: string
    disabled?: boolean
    containerClassName?: string
    arrowConfig?: Omit<ArrowConfig, 'isOpen'>
    bordered?: boolean
    onChange?: (value: boolean) => void
    withTransition?: boolean
    closeOnClickOutside: boolean
}

export const Accordion = (props: AccordionProps) => {
    const {
        title,
        children,
        open,
        outerDivClassName = '',
        buttonClassName = '',
        headingClassName = '',
        disabled = false,
        arrowConfig = {
            size: 18,
            className: '',
            position: 'right',
        },
        containerClassName = '',
        onChange = () => {
        },
        bordered = false,
        withTransition = true,
    } = props

    const [isOpen, setIsOpen] = useState(open ?? false) // Accordion should be closed state by default unless otherwise set
    const {isCALIBO} = getPortalType()
    const targetRef: any = useRef(null)

    //If there is an open accordion on the page and someone clicks outside of it, close it
    useEffect(() => {
        if (props.closeOnClickOutside && props.closeOnClickOutside===true) {
            function handleClickOutside(event: Event) {
                if (targetRef.current && !targetRef.current.contains(event.target)) {
                    setIsOpen(props.closeOnClickOutside && isOpen ? false : isOpen)
                    onChange(props.closeOnClickOutside && isOpen ? false : isOpen)
                }
            }

            window.addEventListener("mousedown", handleClickOutside)
            return () => {
                window.removeEventListener("mousedown", handleClickOutside)
            }
        }
    }, [targetRef])

    //Checks if the state of open ever changes and acts accordingly
    useEffect(() => {
        if (props.open === isOpen) return
        setIsOpen(props.open)
    }, [props.open])

    const handleToggleAccordion = () => {
        if (disabled) return
        const update = !isOpen
        setIsOpen(update)
        onChange(update)
    }

    const containerClass = clsx(
        'w-full p-4 grid gap-3',
        bordered && 'border border-t-0 border-gray-300 rounded-b-md',
        containerClassName,
        !isOpen && !withTransition && 'hidden',
    )

    return (
        <div className={clsx('accordion', outerDivClassName, isOpen && 'active-accordion')} ref={targetRef}>
            <div
                className={clsx(
                    'accordion-button flex w-full justify-between items-center cursor-pointer',
                    bordered && 'border rounded-t-md border-gray-300',
                    bordered && !isOpen && 'rounded-b-md',
                    buttonClassName,
                )}
                onClick={handleToggleAccordion}
            >
                {arrowConfig.position === 'left' && (
                    <Arrow {...arrowConfig} isOpen={isOpen}/>
                )}
                <h2
                    className={clsx(
                        'accordion-heading flex-1 cursor-pointer',
                        headingClassName,
                    )}
                >
                    {title}
                </h2>
                {arrowConfig.position === 'right' && (
                    <Arrow {...arrowConfig} isOpen={isOpen}/>
                )}
            </div>
            {withTransition ? (
                <Transition
                    show={isOpen}
                    enter={`transition-opacity ${isCALIBO ? 'duration-0' : 'duration-300'}`}
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave={`transition-opacity ${isCALIBO ? 'duration-0' : 'duration-300'}`}
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={clsx('accordion-body', containerClass)}>
                        {children}
                    </div>
                </Transition>
            ) : (
                <div className={clsx('accordion-body', containerClass)}>{children}</div>
            )}
        </div>
    )
}
export type ArrowConfig = {
    size?: number
    isOpen: boolean
    className?: string
    position?: 'left' | 'right'
}

export const Arrow = (props: ArrowConfig) => {
    const {size = 18, isOpen, className = ''} = props
    return (
        <CaretDown
            size={size}
            className={clsx(
                'transition-all duration-200',
                isOpen && 'rotate-180',
                className,
            )}
            weight="bold"
        ></CaretDown>
    )
}

export default Accordion
