export const nextAuthClient = {

  getSession: async () => {
    let session;
    try {
      session = await fetch('/api/user/session', { method: 'POST' });

    } catch (e) {
      console.error('cant fetch session')
    }


    return session;
  }
}