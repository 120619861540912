import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {TProductCard} from "../../components";
import {TInitialState} from "./wishlistSlice";


const initialState: {
    products:TProductCard[],
    ids:number[],
    total:number
} = {
    products: [],
    ids:[],
    total: 0,
}

const popupWishlistSlice = createSlice({
    name: 'popup-wishlist',
    initialState,
    reducers: {
        setProducts: (state, action: PayloadAction<TProductCard[]>) => {
            state.products = action.payload;
        },

        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },
        setIds(state,action: PayloadAction<number[]>){
            state.ids=action.payload
        },
        removeProductFromWishlist: (state, action: PayloadAction<number>) => {
            state.products = state.products.filter((product) => product.id !== action.payload);
        },
        reset: () => initialState,
    }
});


export const {setProducts, removeProductFromWishlist, setTotal,setIds,reset} = popupWishlistSlice.actions;
export default popupWishlistSlice.reducer;
