'use client'

import React, { useState } from "react";
import { Env } from "./Env";
import { useHotkeys } from "react-hotkeys-hook";

export function Client({ env }: { env: NodeJS.ProcessEnv; }) {
  const [open, setOpen] = useState(false);

  useHotkeys('`', () => setOpen((open) => !open), []);

  return open ? <div style={{ position: 'fixed', inset: 0, zIndex: 1337 }}>
    <div>
      <a href={`https://cf.development.life.digital8.agency`}>ceiling-fans</a>
      <a href={`https://li.development.life.digital8.agency`}>lighting-illusions</a>
    </div>
    <Env value={env} />
  </div> : null;
}
