'use client'
import useUpdateCart from '@licommon/hooks/cart/useUpdateCart'
import { truncate } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import { CartItem } from '../types/Cart'
import { clsx } from '../utils/clsx'
import { formatCurrency } from '../utils/currency'
import { getPortalType } from '../utils/portal'
import { X } from './Icons'

export type CartItemPreviewProps = {
    product: CartItem
    className?: string
    imageClassName?: string
    editable?: boolean
}

export const CartItemPreview = (props: CartItemPreviewProps) => {
    const {
        product,
        className = '',
        imageClassName = '',
        editable = false,
    } = props
    const {updateCart} = useUpdateCart()
    const [quantity, setQuantity] = useState(() => `${product.quantity}`)
    const {isLI} = getPortalType()
    return (
        <div className={`flex ${className} ${!product.isAvailable ? 'bg-red-100' : ''}`}>
            <Image
                src={product?.thumbnail?.url}
                height={80}
                width={80}
                alt="image"
                className={clsx('h-[80px] w-[80px]', imageClassName)}
            />
            <div className="flex flex-col ml-2 flex-1">
                <Link
                    href={`/product/${product.slug}`}
                    className={`${
                        isLI ? 'text-green-800' : 'text-blue-700'
                    } ${!product.isAvailable ? 'line-through' : ''} text-sm underline text-left font-medium`}
                >
                    {truncate(`${product.name} ${product.parentName}`, {
                        length: 60,
                    })}
                </Link>
                <div className="text-[0.875rem] text-gray-500 text-left">
                    Qty:{' '}
                    {editable ? (
                        <input
                            className="h-[25px] w-[80px] pr-[3px] pl-[8px]"
                            type="number"
                            min={1}
                            onChange={async (e) => {
                                const val = e.target.value
                                if (parseInt(val) > 0) {
                                    updateCart(product.productId, parseInt(val)).then((success) => {
                                            setQuantity(val)
                                    })
                                } else {
                                    setQuantity(val)
                                }
                            }}
                            value={quantity}
                            disabled={!product.isAvailable}
                        ></input>
                    ) : (
                        product.quantity
                    )}
                </div>
                {quantity == "" ? (
                    <div className="text-[0.75rem] text-red-500 text-left">
                        Please enter a quantity.
                    </div>
                ) : <></>}
            </div>
            <div className="flex flex-col flex-shrink-0 text-right w-[80px] font-semibold">
                <div className="text-gray-900 text-[0.875rem]">
                    {formatCurrency(product.totalPrice)}
                </div>
                {product.totalDiscount > 0 && (
                    <div className="text-red-600 text-[0.688rem] font-medium">
                        Save {formatCurrency(product.totalDiscount)}
                    </div>
                )}
            </div>
            {editable && (
                <X
                    className="ml-3 cursor-pointer"
                    onClick={() => {
                        updateCart(product.productId, 0)
                    }}
                    weight="bold"
                    size={20}
                />
            )}
        </div>
    )
}
export default CartItemPreview
