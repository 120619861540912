'use client'

import { setCartData, setIsFetching } from '@licommon/hooks/store/features/cartSlice'
import { useAppSelector } from '@licommon/hooks/store/hooks'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CartSummary } from '../../types/Cart'
import { getSessionToken } from '../../utils/session'
import usePost from '../usePost'
type TCartResponse = { original: { message: CartSummary } }

const useRefetchCart = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn)
  const restoreCart = useAppSelector((state) => state.cart.restoreCart)
  const { post: getCart } = usePost<{}, TCartResponse>('api.coupon.getTotals')
  const resetCart = useCallback(
    async (data = {}) => {
      return new Promise<null | CartSummary>((resolve, reject) => {
        const sessionToken = getSessionToken()
        if (!sessionToken && !isLoggedIn && !restoreCart) {
          reject(null)
        } else {
          dispatch(setIsFetching(true))
          getCart({
            session_token: restoreCart ? restoreCart : sessionToken,
            includeTransitInsurance: true,
            restoreCart: restoreCart,
            ...data,
          })
            .then((data) => {
              const cartData = data.original.message
              dispatch(setCartData(cartData))
              resolve(cartData)
            })
            .finally(() => {
              dispatch(setIsFetching(false))
            })
        }
      })
    },
    [getCart, dispatch, isLoggedIn, restoreCart],
  )
  return resetCart
}
export default useRefetchCart
