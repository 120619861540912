'use client'
import get from 'lodash/get'
import { useEffect, useMemo } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import useFetch from '../../../../hooks/useFetch'
import { Product } from '../../../../types/Product'
import ProductCategory from '../../../../types/ProductCategory'
import { TProductRangeData } from '../../../range/ProductRangeInner'
type SearchApiResponse = {
  products: Product[]
  categories: ProductCategory[]
}

const useGlobalSearch = (search: string) => {
  const { data, loading, fetch } = useFetch<SearchApiResponse>(
    'api.global-search',
    {},
    false,
    { cache: 'force-cache' },
  )
  const debouncedSearch = useDebounce<string>(
    search,
    parseInt(process.env.NEXT_PUBLIC_SEARCH_DEBOUNCE || '500', 10),
  )
  useEffect(() => {
    if (!debouncedSearch) return
    fetch({
      searchValue: search,
      siteId: process.env.NEXT_PUBLIC_SITE_ID,
    })
  }, [debouncedSearch])

  const products: Product[] = useMemo(() => get(data, 'products', []), [data])
  const ranges: TProductRangeData[] = useMemo(
    () => get(data, 'ranges', []),
    [data],
  )
  const categories: ProductCategory[] = useMemo(
    () => get(data, 'categories', []),
    [data],
  )
  return { products, ranges, categories, loading, debouncedSearch }
}
export default useGlobalSearch
