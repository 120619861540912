import { Models } from 'common/types/Models';
import { Site } from 'common/types/Site';
import ziggy from './ziggy';

const BACKEND_API_URL = process.env.NEXT_PUBLIC_BACKEND_API_URL

interface PathRoute {
  path: string
}

interface ZiggyRoute {
  name: string
  options?: object
}

type Route = PathRoute | ZiggyRoute

function isZiggyRoute(route: Route): route is ZiggyRoute {
  return !!(route as ZiggyRoute).name
}

function isPathRoute(route: Route): route is PathRoute {
  return !!(route as PathRoute).path
}

const client = ({} = {}) => {
  const normaliseRoute = async (url: Route): Promise<string> => {
    if (isZiggyRoute(url)) {
      return await ziggy.route(url.name, url?.options)
    }
    return `${BACKEND_API_URL}/${url.path}`
  }

  const configure = ({
    cache,
    ...customOptions
  }: RequestInit & { next?: { revalidate: number } } = {}) => {
    const headers = customOptions['headers']
      ? { ...customOptions['headers'] }
      : {}
    delete customOptions['headers']

    const ret = {
      headers: {
        ...{
          Accept: 'application/json',
        },
        ...headers,
      },
      ...customOptions,
      // cache:
      //   cache ||
      //   (typeof customOptions.next?.revalidate === 'number'
      //     ? undefined
      //     : 'no-cache'),
      cache: 'force-cache'
    }

    return ret
  }

  const request = async <TResponse>(
    url: Route,
    config: RequestInit = {},
  ): Promise<TResponse> => {
    // TODO normalise URL if it is a prefix only using the BACKEND_URL
    const normalisedUrl = await normaliseRoute(url)

    try {
      
      const response = await fetch(normalisedUrl, configure(config))
      
      return response.json()
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        throw e
      }
    }
  }

  const fetchReq = async <T>(url: Route): Promise<T | undefined> => {
    const normalisedUrl = await normaliseRoute(url)

    try {
      const res = await fetch(normalisedUrl, {
        headers: { Accept: 'application/json' },
      })

      return res.json()
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        throw e
      }
    }
  }

  const api = {
    request: request,

    get: <TResponse>(
      url: Route,
      config: RequestInit & { next?: any } = {},
    ): Promise<any> => {

   
      return request<TResponse>(url, configure(config))
    },

    getFooterMenu: async () => {
      const res = await fetchReq<Models.Menu>({
        name: 'api.menu.show',
        options: {
          name: 'Footer',
          site_id: process.env.NEXT_PUBLIC_SITE_ID,
          next: { tags: ['menu'] },
        },
      })

      return res
    },

    getFooterSiteDetails: async (): Promise<Site | undefined> => {
      const res = await fetchReq<Site>({
        name: `api.site-details`,
        options: {
          site: process.env.NEXT_PUBLIC_SITE_ID,
          next: { tags: ['site-details'] },
        },
      })

      return res
    },

    post: <TResponse>(
      url: Route,
      body: any,
      options: RequestInit = {},
    ): Promise<any> => {
      const isFormData = body instanceof FormData
      return request<TResponse>(
        url,
        configure({
          method: 'POST',
          body:
            typeof body === 'object' && !isFormData
              ? JSON.stringify(body)
              : body,
          ...options,
        }),
      )
    },

    put: <TBody extends BodyInit, TResponse>(
      url: Route,
      body: TBody,
      options: RequestInit = {},
    ) => {
      return request<TResponse>(
        url,
        configure({
          method: 'PUT',
          body: typeof body === 'object' ? JSON.stringify(body) : body,
          ...options,
        }),
      )
    },
  }

  return {
    api,
    defaultOptions: configure,
  }
}

export default client()
