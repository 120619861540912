'use client'

import { useAppSelector } from '@licommon/hooks/store'
import { nextAuthClient } from 'api/auth'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { ReactNode, useEffect, useRef, useState } from 'react'
import AccountActionPreview from '../../../../../components/AccountActionsPreview'
import DoAuthPreview from '../../../../../components/DoAuthPreview'
import {
  Eye,
  ShoppingCart,
  Star,
  Storefront,
  UserCircle,
} from '../../../../../components/Icons'
import NotificationBubble from '../../../../../components/NotificationBubble'
import ViewCartPreview from '../../../../../components/ViewCartPreview'
import ViewWishlistPreview from '../../../../../components/ViewWishlistPreview'
import { getPortalType } from '../../../../../utils/portal'
import HeaderSearchModal from '../../components/HeaderSearchModal'
import useGlobalSearch from '../../hooks/useGlobalSearch'
import { MenuItem } from './MenuItem'
import { SearchBar } from './SearchBar'

export default function HeaderSearchBar({
  logo,
  showStore,
  showRecentlyViewed,
  searchModalClasses,
}: {
  logo: ReactNode
  showStore?: Boolean
  showRecentlyViewed?: Boolean
  searchModalClasses?: string
}) {
  const isUserLoggedIn = useAppSelector((state) => state.auth.isLoggedIn)

  const [showWishlistModal, setShowWishlistModal] = useState(false)
  const [showCartModal, setShowCartModal] = useState(false)
  const [showAuthModal, setShowAuthModal] = useState(false)
  const wishlistContainer = useRef(null)
  const cartContainer = useRef(null)
  const authContainer = useRef(null)
  const [search, setSearch] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const searchRef = useRef(null)
  const [status, setStatus] = useState('unauthenticated')
  const [session, setSession] = useState({})
  const url = usePathname()
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn)

  useEffect(() => {
    nextAuthClient
      .getSession()
      .then(async (d) => {
        const res = await d?.json()
        setSession(res.session)
      })
      .catch((e) => console.log('error fetching session'))
  }, [isUserLoggedIn])

  useEffect(() => {
    if (session && session?.user) {
      setAuthenticated(true)
      setStatus('authenticated')
    } else {
      setAuthenticated(false)
    }
  }, [session?.user])
  useEffect(() => {
    setShowSearch(false)
    setShowCartModal(false)
    setShowAuthModal(false)
    setShowWishlistModal(false)
  }, [url])
  const { isLI, isCALIBO } = getPortalType()

  const { products, ranges, categories, loading, debouncedSearch } =
    useGlobalSearch(search)

  const cartItemsCount = useAppSelector((state) => state.cart.noOfItems)
  const wishListCount = useAppSelector((state) => state.wishlistPopup.total)

  return (
    <div className="nav-search-container">
      <div className="max-w-page md:max-w-[92.5vw] flex mx-auto w-full h-full relative">
        <Link href="/" className="flex justify-center items-center">
          {logo}
        </Link>
        <div className="w-6 hidden lg:flex"></div>
        <div className="h-auto flex-1 hidden md:flex py-1">
          <SearchBar
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            ref={searchRef}
            onShow={() => setShowSearch(true)}
            onClose={() => setShowSearch(false)}
            searchModalClasses={searchModalClasses}
          >
            <HeaderSearchModal
              onClose={() => setShowSearch(false)}
              containerRef={searchRef}
              show={showSearch}
              searchTerm={debouncedSearch}
              products={!isCALIBO ? products : []}
              ranges={isCALIBO ? ranges : []}
              categories={categories}
              loading={loading}
            />
          </SearchBar>
        </div>
        {isLI && <div className="w-6 hidden lg:flex"></div>}
        {!isCALIBO && (
          <div className="flex-1 md:flex-none font-medium flex justify-between h-full">
            {showStore && (
              <MenuItem link="/store" icon={<Storefront />} text={'Stores'} />
            )}
            {showRecentlyViewed && (
              <MenuItem
                link="/account/recently-viewed"
                icon={<Eye />}
                text={'Recently Viewed'}
              />
            )}
            <MenuItem
              id="wishlist-in-header"
              onMouseEnter={() => {
                setShowWishlistModal(true)
                setShowAuthModal(false)
                setShowCartModal(false)
              }}
              onClick={() => {
                setShowWishlistModal((old) => !old)
              }}
              icon={<Star />}
              text={'Wishlist'}
              active={showWishlistModal}
              ref={wishlistContainer}
            >
              {wishListCount !== 0 ? (
                <NotificationBubble
                  className="top-2 right-3"
                  count={wishListCount}
                />
              ) : null}
              <ViewWishlistPreview.Modal
                show={showWishlistModal}
                containerRef={wishlistContainer}
                onClose={() => setShowWishlistModal(false)}
              />
            </MenuItem>
            <MenuItem
              id="profile-in-header"
              href={`${isLoggedIn ? '/account' : '/auth/login'}`}
              onMouseEnter={() => {
                setShowAuthModal(true)
                setShowWishlistModal(false)
                setShowCartModal(false)
              }}
              ref={authContainer}
              onClick={() => {
                setShowAuthModal((old) => !old)
              }}
              active={showAuthModal}
              icon={<UserCircle />}
              text={authenticated ? 'Profile' : 'Login'}
            >
              {status === 'authenticated' ? (
                <AccountActionPreview.Modal
                  show={showAuthModal}
                  containerRef={authContainer}
                  onClose={() => setShowAuthModal(false)}
                />
              ) : (
                <DoAuthPreview.Modal
                  show={showAuthModal}
                  containerRef={authContainer}
                  onClose={() => setShowAuthModal(false)}
                />
              )}
            </MenuItem>
            <MenuItem
              id="cart-in-header"
              href="/cart"
              ref={cartContainer}
              icon={<ShoppingCart />}
              text={'My Cart'}
              active={showCartModal}
              onMouseEnter={() => {
                setShowCartModal(true)
                setShowWishlistModal(false)
                setShowAuthModal(false)
              }}
              onClick={() => {
                setShowCartModal((old) => !old)
              }}
            >
              {cartItemsCount !== 0 ? (
                <NotificationBubble
                  className="top-2 right-3"
                  count={cartItemsCount}
                />
              ) : null}
              <ViewCartPreview.Modal
                show={showCartModal}
                containerRef={cartContainer}
                onClose={() => setShowCartModal(false)}
              />
            </MenuItem>
          </div>
        )}
      </div>
    </div>
  )
}
