"use client";

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { setCartData } from "@licommon/hooks//store/features/cartSlice";
import { useAppSelector } from "@licommon/hooks//store/hooks";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { CartSummary } from "../../types/Cart";
import { showError, showSuccess } from "../../utils/alert";
import { getSessionToken, setSessionToken } from "../../utils/session";
import usePost from "../usePost";

type TCartResponse = { message: CartSummary };
const useAddToCart = () => {
  const items = useAppSelector((state) => state.cart.products);
  const { post, loading } = usePost<{}, TCartResponse>("api.cart.add-cart");
  const dispatch = useDispatch();

  const sendDataToGTM = useGTMDispatch();

  const addToCart = useCallback(
    (id: number, quantity = 1) => {
      const postData: Record<number, { quantity: number; productId: number }> =
        {};
      items.forEach((item) => {
        postData[item.productId] = {
          quantity: item.quantity,
          productId: item.productId,
        };
      });
      if (id in postData) {
        postData[id].quantity += quantity;
      } else {
        postData[id] = {
          quantity,
          productId: id,
        };
      }
      post({ cart: postData, session_token: getSessionToken() })
        .then((data) => {
          if(data?.success===false) return showError(data?.message);
          setSessionToken(data.message.session_token);
          dispatch(setCartData(data.message));
          showSuccess("Item added to cart successfully!");
          const cartItems = data.message.products.filter(item=>item.productId===id)
            cartItems.forEach(item => {
              sendDataToGTM({
                'event': 'addToCart',
                'ecommerce': {
                  'add': {
                    'products': [{
                      name: `${item.name} ${item.parentName}`,
                      id: String(item.sku),
                      price: String(item.salePrice).replace('$', ''),
                      quantity
                    }]
                  }
                }
              })
            }
          )
        })
        .catch(() => {
          showError("There was an error adding this item to cart!");
        });
    },
    [items]
  );

  return { addToCart, loading };
};

export default useAddToCart;
