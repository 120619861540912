'use client'
import { Popover } from '@headlessui/react'
import { clsx } from '@licommon/utils/clsx'
import { getPortalType } from '@licommon/utils/portal'
import Link from 'next/link'
import { Fragment, MutableRefObject, useRef } from 'react'
import useHandleLogout from '../hooks/auth/useHandleLogout'
import useOnClickOutside from '../hooks/useOnClickOutside'
import { UserData } from '../types/User'
import Button from './Button'
import WithTransition from './hoc/WithTransition'

type ModalProps = {
  show: boolean
  onClose?: Function
  containerRef?: MutableRefObject<any>
  className?: string
}

const Modal = (props: ModalProps) => {
  const { show, onClose = () => {}, containerRef, className = '' } = props
  const ref = useRef(null)
  useOnClickOutside(containerRef || ref, onClose)
  return (
    <div
      ref={containerRef || ref}
      onMouseLeave={() => {
        onClose()
      }}
    >
      <Popover as={Fragment}>
        <WithTransition
          show={show}
          className={`absolute z-50 overflow-hidden top-[76px] -left-[157px] md:border border-gray-300 w-[314px] bg-white text-gray-900 md:border-t-0 md:rounded-b-md ${className}`}
        >
          <Popover.Panel static className="flex-1 flex">
            <NoModal {...props} />
          </Popover.Panel>
        </WithTransition>
      </Popover>
    </div>
  )
}

type AccountActionsNoModalProps = {
  onClose?: Function
  user?: UserData
}

const MENU_ITEMS = [
  {
    title: 'My Account',
    href: '/account',
  },
  {
    title: 'My Orders',
    href: '/account/orders',
  },
  {
    title: 'My Wishlist',
    href: '/account/wishlist',
  },
  {
    title: 'Support',
    href: '/account/contact-us',
  },
]

const NoModal = (props: AccountActionsNoModalProps) => {
  const { onClose = () => {} } = props
  const { isLI, isCFD } = getPortalType()
  const handleLogout = useHandleLogout()
  return (
    <div className="flex flex-col w-full p-2">
      <div className="flex flex-col w-full">
        {MENU_ITEMS.map((item) => (
          <Link
            key={item.title}
            href={item.href}
            className="p-2 font-medium text-gray-500"
          >
            {item.title}
          </Link>
        ))}
        <Button
          onClick={() => {
            handleLogout()
          }}
          className={clsx(
            'flex w-full justify-center mt-2',
            isCFD && 'rounded-lg',
          )}
          variant={isLI ? 'success' : 'blue'}
        >
          Logout
        </Button>
      </div>
    </div>
  )
}

const AccountActionPreview = {
  Modal: Modal,
  NoModal: NoModal,
}

export default AccountActionPreview
