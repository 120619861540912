'use client'
import useCompare, { COMPARE_ITEMS_LIMIT } from '../../hooks/compare/useCompare'
import BottomBar from '../BottomBar'
import Button from '../Button'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { X } from '../Icons'
import { useState } from 'react'

const CompareBottom = () => {
  const { compareItems, clearCompare, removeFromCompare } = useCompare()
  const router = useRouter()
  const [open, setOpen] = useState(false)

  return (
    <>
      {compareItems.length > 0 && (
        <BottomBar
          title="Compare Products"
          headerClassName="text-blue-700 text-[16px] xs:text-[24px] font-medium"
          buttonClassName="justify-center"
          open={open}
          onChange={setOpen}
          arrowConfig={{
            position: 'left',
            size: 24,
            className: 'text-blue-700 font-medium',
          }}
        >
          <div className="compare-bottom w-full flex justify-center mt-[24px]">
            <div className="compare-bottom__inner flex justify-center flex-wrap items-center gap-8">
              <div className="compare-bottom__products flex flex-wrap justify-center items-center gap-[16px]">
                {compareItems.map((item) => {
                  return (
                    <div
                      className="relative w-[80px] h-[80px] sm:w-[120px] sm:h-[120px]"
                      key={item.id}
                    >
                      <Image
                        src={item.img}
                        width={120}
                        height={120}
                        alt="Compare product"
                        className="rounded-md border border-gray-300"
                        loading="eager"
                        priority={true}
                      ></Image>
                      <div
                        onClick={() => {
                          removeFromCompare(item)
                        }}
                        className="absolute bg-primary font-bold rounded-full text-white top-[7px] right-[7px] cursor-pointer flex items-center justify-center w-[25px] h-[25px]"
                      >
                        <X strokeWidth={2} className="font-bold"></X>
                      </div>
                    </div>
                  )
                })}
                {Array.from(
                  Array(COMPARE_ITEMS_LIMIT - compareItems.length).keys(),
                ).map((item) => {
                  return (
                    <div
                      key={item}
                      className="compare-bottom__products__frame w-[80px] h-[80px] sm:w-[120px] rounded-md sm:h-[120px] border border-gray-400 border-dashed bg-gray-100"
                    />
                  )
                })}
              </div>
              <div className="compare-bottom__buttons flex items-center gap-3">
                <Button onClick={clearCompare}>Clear All</Button>
                <Button
                  onClick={() => {
                    router.push('/compare-products')
                    setOpen(false)
                  }}
                  className="bg-primary text-white border-none hover:shadow hover:bg-primary"
                >
                  Compare
                </Button>
              </div>
            </div>
          </div>
        </BottomBar>
      )}
    </>
  )
}

export default CompareBottom
