import { Models } from '../../../../types/Models'
import MenuItem = Models.MenuItem

const useSlugEncoder = () => {
  return (item: MenuItem) => {
    if (
      !item.mapping &&
      item.linked_type &&
      item.linked_type.includes('LiPreDefinedAttribute')
    ) {
      let url = `/product?`
      item.linked_config.forEach((config, ind) => {
        url +=
          (ind > 0 ? '&' : '') +
          encodeURIComponent(`attributeFilters[${item.linked_id}]`) +
          '=' +
          config.slug.replace(' ', '+')
      })
      return url
    }
    if (!item.slug) {
      // No slug
      // We will return the # symbol to make the link empty
      return '#'
    }
    switch (item.mapping) {
      case 'category':
        return `/category/${encodeURIComponent(item.slug)}`
      case 'product':
        return `/product/${encodeURIComponent(item.slug)}`
      case 'tag':
        return `/tag/${encodeURIComponent(item.slug)}`
      case 'page':
        return `/blog/${item.slug}`
      case 'url:internal':
        if (item.slug.includes('/')) {
          //The slug also contains an url path
          const newSlug = item.slug.split('/').map((part) => {
            return encodeURIComponent(part);
          }).join('/')
          return `/${newSlug}`
        }
        return `/${encodeURIComponent(item.slug)}`
      default:
        return `/${encodeURIComponent(item.slug)}`
    }
  }
}
export default useSlugEncoder
