'use client'
import { CaretDown, CaretUp, List } from '@licommon/components/Icons'
import { clsx } from '@licommon/utils/clsx'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import useHandleLogout from '../../../hooks/auth/useHandleLogout'
import { getPortalType } from '../../../utils/portal'
import Divider from '../../Divider'

export type SidebarLink = {
  id: number
  title: string
  slug: string
  items?: SidebarLink[]
  description?: string
  color: string
  onClick?: () => any
}

type SidebarProps = {
  userName?: string
  sidebarLinks: SidebarLink[]
  showCaretBtn?: boolean
  containerClass?: string
  innerContainer?: string
  linkClass?: string
  subLinkClass?: string
}

const Sidebar = (props: SidebarProps) => {
  const {
    userName,
    sidebarLinks,
    showCaretBtn = true,
    containerClass = '',
    innerContainer = '',
    linkClass = '',
    subLinkClass = '',
  } = props

  const [expandedItems, setExpandedItems] = useState<number[]>([])
  const [isSidebarVisible, setSidebarVisible] = useState(false)
  const { isCFD } = getPortalType()
  const handleLogout = useHandleLogout()
  const route = usePathname()
  const router = useRouter()
  const isLinkActive = (slug: string) => {
    return route === slug
  }
  const liActiveClassName = 'bg-green-100 rounded-lg text-green-800'
  const cfdActiveClassName =
    'bg-gray-100  lg:!text-blue-700 rounded-lg lg:rounded-none'
  const toggleExpand = (id: number) => {
    if (expandedItems.includes(id)) {
      setExpandedItems(expandedItems.filter((item) => item !== id))
    } else {
      setExpandedItems([...expandedItems, id])
    }
  }

  useEffect(() => {
    if (route === '/account/wishlist' || route === '/accout/recently-viewed')
      setExpandedItems((old) => [...old, 3])
  }, [route])

  return (
    <div className="flex flex-col center align-middle sidebar mb-6">
      <button
        className={clsx(
          'lg:hidden py-1 rounded-lg flex flex-row mobile-sidebar place-content-between items-center',
          !isCFD ? 'bg-gray-200 px-3' : '',
        )}
        onClick={() => setSidebarVisible(!isSidebarVisible)}
      >
        <div className="flex items-center h-12 text-xl font-medium">
          Welcome {userName}
        </div>
        <List size={26} />
      </button>

      <div
        className={clsx(
          'px-2 pr-0 text-gray-900 flex flex-col w-full lg:w-72',
          !isSidebarVisible && 'hidden lg:block',
          isCFD && 'lg:border lg:border-gray-300 lg:rounded-lg !px-0',
        )}
      >
        <div
          className={clsx(
            'hidden lg:flex items-center h-12 text-xl font-medium px-2',
            isCFD && 'py-3',
          )}
        >
          Welcome {userName}
        </div>
        {!isCFD && <Divider className="hidden lg:block" />}
        <div className={clsx('flex flex-col', !isCFD && 'my-4')}>
          {sidebarLinks.map((link) => (
            <div key={link.id} className={clsx(containerClass)}>
              <div
                className={clsx(
                  `flex items-center justify-between cursor-pointer text-gray-500 py-3 px-2 ${
                    isLinkActive(link.slug) &&
                    (isCFD ? cfdActiveClassName : liActiveClassName)
                  }`,
                  innerContainer,
                )}
                onClick={() => {
                  if (link.items && link.items.length > 0) {
                    toggleExpand(link.id)
                  } else {
                    router.push(link.slug)
                    setSidebarVisible(false)
                  }
                }}
              >
                {link.title == 'Logout' ? (
                  <>
                    <div
                      onClick={() => {
                        handleLogout()
                      }}
                      className={clsx(
                        isLinkActive(link.slug) && 'text-primary',
                        linkClass,
                      )}
                    >
                      {link.title}
                    </div>
                    {/* {isCFD && (
                      <div
                        className={clsx(
                          isCFD && 'pl-2 lg:pl-0',
                          'block lg:hidden',
                        )}
                      >
                        {link.description}
                      </div>
                    )} */}
                  </>
                ) : (
                  <>
                    <Link href={link.slug}>
                      <div
                        className={clsx(
                          isLinkActive(link.slug) && 'text-primary',
                          linkClass,
                        )}
                      >
                        {link.title}
                      </div>
                    </Link>
                    {/* {isCFD && (
                      <div
                        className={clsx(
                          isCFD && 'pl-2 lg:pl-0',
                          'block lg:hidden',
                        )}
                      >
                        {link.description}
                      </div>
                    )} */}
                  </>
                )}
                {link.items && link.items.length > 0 && (
                  <>
                    {showCaretBtn && (
                      <>
                        {expandedItems.includes(link.id) ? (
                          <CaretUp />
                        ) : (
                          <CaretDown />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              {expandedItems.includes(link.id) && link.items && (
                <div>
                  {link.items.map((subItem) => (
                    <Link
                      href={subItem.slug}
                      onClick={() => {
                        setSidebarVisible(false)
                      }}
                    >
                      <div
                        className={clsx(
                          `py-3 px-4 text-gray-500  mb-1 ${
                            isLinkActive(subItem.slug) &&
                            (isCFD ? cfdActiveClassName : liActiveClassName)
                          }`,
                          subLinkClass,
                        )}
                        key={subItem.id}
                      >
                        <Link href={subItem.slug}>
                          <div className="">{subItem.title}</div>
                        </Link>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {!isCFD && <Divider className="hidden lg:block border-r bg-gray-300" />}
    </div>
  )
}

export default Sidebar
