import { clsx } from "../utils/clsx";

type NotificationBubbleProp = {
  count: number;
  className?: string;
  size?: "sm" | "md" | "lg";
};

const NotificationBubble = (props: NotificationBubbleProp) => {
  const { count, className = "", size = "md" } = props;
  return (
    <div
      className={clsx(
        "absolute bg-danger top-0 right-0 rounded-full text-white flex justify-center text-sm items-center pointer-events-none",
        className,
        size == "md" && "h-5 w-6 text-xs",
        size == "sm" && "h-[18px] w-[22px] text-xs",
        size == "lg" && "h-6 w-7 text-md"
      )}
    >
      {count > 9 ? "9+" : count}
    </div>
  );
};

export default NotificationBubble;
