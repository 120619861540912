'use client'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@licommon/components/Button'
import usePost from '@licommon/hooks/usePost'
import { showError, showSuccess } from '@licommon/utils/alert'
import { clsx } from '@licommon/utils/clsx'
import { getPortalType } from '@licommon/utils/portal'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

export type FooterNewsletterProps = {
  newsletterClassnames: string
  newsletterTextSize: string
  newsletterFormClassnames?: string
  newsletterFormContainerClassnames?: string
  newsletterInputClassnames?: string
  buttonVariant: 'success' | 'secondary' | 'danger' | 'warning' | 'blue'
  formNote?: string
}

const FooterNewsletter = ({
  newsletterClassnames,
  newsletterTextSize,
  newsletterFormClassnames,
  newsletterFormContainerClassnames,
  newsletterInputClassnames,
  buttonVariant,
  formNote,
}: FooterNewsletterProps) => {
  const { post, loading } = usePost('api.subscribe')
  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email.')
      .required('Please enter an email.'),
  })
  const { isCFD } = getPortalType()
  const form = useForm({ resolver: yupResolver(schema) })
  const {
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = form

  const email = watch('email')
  const {isCALIBO} = getPortalType();

  const subscribe = () => {
    post({ email, subscribed: true })
      .then((res) => {
        showSuccess(
          'Thank you for your submission, you are now subscribed to our newsletter',
        )
      })
      .catch(() => {
        showError(
          'We could not process your request, please try again. If the problem persists, please contact us',
        )
      })
      .finally(() => {
        reset({ email: '' })
      })
  }

  return (
    <div
      className={clsx(
        newsletterClassnames,
        'w-full md:max-w-content mx-auto flex  px-3 py-5 sm:px-3',
      )}
    >
      <div>
        <div className={clsx(newsletterTextSize, ' text-center')}>
        { isCALIBO ? 'Stay updated' : 'Sign up to our newsletter'}
        </div>
        <div
          className={clsx(
            'opacity-70 mb-5',
            isCFD ? 'text-left' : 'text-center',
          )}
        >
          { isCALIBO ? 'Sign up for our newsletter to get updates straight to your inbox.' : 'Get instant deals & exclusive offers'}
        </div>
      </div>
      <div className={clsx(newsletterFormContainerClassnames)}>
        <div className={clsx(newsletterFormClassnames, 'relative h-[48px]')}>
          <input
            placeholder="Enter your email"
            {...register('email')}
            onKeyDown={(event: any) => {
              if (event.code === 'Enter') {
                event.preventDefault()
                handleSubmit(subscribe)()
              }
            }}
            className={clsx(
              newsletterInputClassnames,
              'outline-none h-full border w-full self-stretch px-4 text-sm border-gray-300 border-1  pr-[132px] first-letter:uppercase',
            )}
          />
          <Button
            variant={buttonVariant}
            loading={loading}
            onClick={handleSubmit(subscribe)}
            className={clsx(
              newsletterInputClassnames,
              'absolute right-[3px] top-[3px] h-[42px] h-100 px-4',
            )}
          >
            Subscribe
          </Button>
        </div>
        {errors.email && (
          <div className="text-danger text-[0.72rem] mt-2 ml-5 first-letter:capitalize">
            {errors.email.message}
          </div>
        )}
        {formNote && (
          <div className="text-[12px] text-gray-900 mt-2">{formNote}</div>
        )}
      </div>
      { isCALIBO && (<p className='fine-print'>By clicking "Subscribe" you agree to receive all of our latest news and specials.</p>)}
    </div>
  )
}
export default FooterNewsletter
