export const NO_INDEX_RESPONSE={
  robots: {
    index: false,
    follow: true,
    nocache: true,
    googleBot: {
      index: true,
      follow: false,
      noimageindex: true,
      'max-video-preview': -1,
      'max-image-preview': 'large',
      'max-snippet': -1,
    },
  },
}

export const INDEXABLE_DOMAINS = ['https://www.ceilingfansdirect.com.au', 'https://www.lightingillusions.com.au', 'https://www.calibo.com.au']