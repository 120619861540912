'use client'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { setCartData } from '@licommon/hooks/store/features/cartSlice'
import { useAppSelector } from '@licommon/hooks/store/hooks'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CartSummary } from '../../types/Cart'
import { showError } from '../../utils/alert'
import { getSessionToken, setSessionToken } from '../../utils/session'
import usePost from '../usePost'

type TCartResponse = { message: CartSummary }
export type TCartUpdateParam = Record<
  number,
  { quantity: number; productId: number }
>
const useUpdateCart = () => {
  const items = useAppSelector((state) => state.cart.products)
  const { post, loading } = usePost<{}, TCartResponse>('api.cart.add-cart')
  const dispatch = useDispatch()

  const sendDataToGTM = useGTMDispatch()

  const updateCart = useCallback(
    (id: number, quantity: number) => {
      const postData: TCartUpdateParam = {}
      items.forEach((item) => {
        postData[item.productId] = {
          quantity: item.quantity,
          productId: item.productId,
        }
      })
      if (quantity) {
        // check if item is removed or added
        const allProducts = items.filter((item) => item.productId === id)
        if (quantity > postData[id].quantity) {
          allProducts.forEach((product) => {
            sendDataToGTM({
              event: 'addToCart',
              ecommerce: {
                add: {
                  products: [
                    {
                      name: `${product.name} ${product.parentName}`,
                      id: String(product.sku),
                      price: String(product.salePrice).replace('$', ''),
                      quantity,
                    },
                  ],
                },
              },
            })
          })
        } else {
          allProducts.forEach((product) => {
            sendDataToGTM({
              event: 'removeFromCart',
              ecommerce: {
                remove: {
                  products: [
                    {
                      name: `${product.name} ${product.parentName}`,
                      id: String(product.sku),
                      price: String(product.salePrice).replace('$', ''),
                      quantity,
                    },
                  ],
                },
              },
            })
          })
        }
        postData[id].quantity = quantity
      } else {
        const deletedItems = items.filter((item) => item.productId === id)
        sendDataToGTM({
          event: 'removeFromCart',
          ecommerce: {
            remove: {
              products: [
                {
                  name: `${deletedItems[0].name} ${deletedItems[0].parentName}`,
                  id: String(deletedItems[0].sku),
                  price: String(deletedItems[0].salePrice).replace('$', ''),
                  quantity: deletedItems[0].quantity,
                },
              ],
            },
          },
        })
        delete postData[id]
      }
      return post({ cart: postData, session_token: getSessionToken() }).then(
        (data) => {
          if (data?.success === false) {
            showError(data?.message)
            return false
          }
          setSessionToken(data.message.session_token)
          dispatch(setCartData(data.message))
          return true
        },
      )
    },
    [items],
  )

  const refreshCart = useCallback(
    (data: Record<any, any>) => {
      const postData: TCartUpdateParam = {}
      items.forEach((item) => {
        postData[item.productId] = {
          quantity: item.quantity,
          productId: item.productId,
        }
      })
      post({ cart: postData, session_token: getSessionToken(), ...data }).then(
        (data) => {
          setSessionToken(data.message.session_token)
          dispatch(setCartData(data.message))
        },
      )
    },
    [items],
  )

  return { updateCart, refreshCart, loading }
}

export default useUpdateCart
