import { clsx } from "../utils/clsx";

const Divider = ({
  className,
  type = "horizontal",
}: {
  className?: string;
  type?: "horizontal" | "vertical";
}) => {
  return (
    <div
      className={clsx(
        "border-gray-300 self-stretch",
        className,
        type == "horizontal" && "border-b",
        type == "vertical" && "border-r"
      )}
    ></div>
  );
};

export default Divider;
