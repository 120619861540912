import { REVALIDATE } from '@licommon/utils/fetch'
import { NextRequest, NextResponse } from 'next/server'

export type Redirects = Redirect[]

export interface Redirect {
  id: number
  site_id: number
  from: string
  to: string
  entity_type: string
  entity_id: number
  status: number
  created_at: string
  updated_at: string
  type: string
}

export const API = [process.env.NEXT_PUBLIC_BACKEND_API_URL!, `api`].join('/')
export const HAS_REDIRECTS = ['tag', 'product', 'blog', 'category']

export default async function redirectsMiddleware(req: NextRequest) {
  if (HAS_REDIRECTS.some((red) => req.nextUrl.pathname.includes(red))) {
    const redirects: Redirects = await fetch(
      [API, `core/redirects?site_id=${process.env.NEXT_PUBLIC_SITE_ID}`].join(
        '/',
      ),
      { next: { revalidate: REVALIDATE, tags: ['redirects'] } },
    ).then((res) => res.json())
    const redirect = redirects?.find((redirect) => {
      // this assumes that the type is correctly mapped to the path.
      // If it is not (i.e. if custom 302s are implemented in the future), this will not work. Additional mapping would be required
      return `/${redirect.type}/${redirect.from}` === req.nextUrl.pathname
    })
    if (redirect) {
      return NextResponse.redirect(
        new URL(`/${redirect.type}/${redirect.to}`, req.url),
      )
    }
  }
}
