'use client'

import { decodeCssString } from '@licommon/utils/css'
import { CSSProperties } from 'react'
import { Models } from '../../../../../../types/Models'
import checkNeedReload from './ForceReload'
type HeaderMegaMenuProps = {
  item: Models.MenuItem
  setActiveItem?: (item: Models.MenuItem, active: boolean) => void
  classNames?: string
  styles?: CSSProperties
  children?: React.ReactNode
}

const MenuItem = ({
  item,
  children,
  styles = {},
  classNames = '',
}: HeaderMegaMenuProps) => {

  return (
    <div
      className={`mega-menu__item ${classNames}`}
      style={{
        ...styles,
        ...decodeCssString(item.customisation?.css as string),
      }}
      onClick={() => checkNeedReload(item.linked?.slug ? item.linked?.slug : '')}
    >
      {children}
    </div>
  )
}

export default MenuItem
