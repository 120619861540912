"use client"
import Divider from '@licommon/components/Divider'
import { CurrencyDollar, Lightning, Truck } from '@licommon/components/Icons'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { ReactNode } from 'react'
const HeaderCompanyStats = () => {
  return (
    <div className="bg-gray-50 h-auto p-[8px] sm:p-3 border-b border-gray-200">
      <div className="max-w-page flex items-center gap-2 sm:gap-0 justify-around mx-auto h-full">
        <CompanyStatItem
          icon={<CurrencyDollar />}
          link={'/30-day-money-back-guarantee'}
          text="30 days money back guarantee"
        />
        <Divider className="mx-3 hidden sm:block" />
        <CompanyStatItem
          icon={<Truck />}
          link={'/free-shipping-terms'}
          text="Free shipping on orders over $150"
        />
        <Divider className="mx-3 hidden sm:block" />
        <CompanyStatItem
          icon={<Lightning />}
          link={'/store'}
          text="Largest Showroom in Australia"
        />
      </div>
    </div>
  )
}

type CompanyStatItemProps = {
  text: string
  link: string
  icon: ReactNode
}

const CompanyStatItem = (props: CompanyStatItemProps) => {
  const { text, link, icon } = props
  const router = useRouter()
  return (
    <Link href={encodeURI(link)} onClick={() => router.refresh()} className="flex items-center justify-between flex-col lg:flex-row">
      <div className="mr-0 lg:mr-3 text-xl lg:text-2xl mb-2 lg:mb-0">
        {icon}
      </div>
      <div className="text-center text-[0.75rem] lg:text-base font-medium">
        {text}
      </div>
    </Link>
  )
}
export default HeaderCompanyStats
