"use client";
import { doGet } from "@licommon/authOptions";
import { useCallback, useEffect, useState } from "react";
type FetchState<T> = {
  loading: boolean;
  data: T | null;
  error: any;
  hasFetched:boolean;
};

const useFetch = <T>(routeName: string, params = {}, immediate = false, customParams={}) => {
  const [state, setState] = useState<FetchState<T>>({
    loading: false,
    data: null,
    error: null,
    hasFetched:false,
  });

  const fetchData = useCallback(
    async (newParams = {}) => {
      setState((prevState) => ({ ...prevState, loading: true }));
      try {
        const data = await doGet<T>(routeName, {...params,...newParams}, {...customParams});
        setState({ loading: false, data, error: null ,hasFetched:true});
        return data;
      } catch (error) {
        setState({ loading: false, data: null, error ,hasFetched:true});
        throw error;
      }
    },
    [routeName, params]
  );

  const refetch = useCallback(
    (newParams = params) => {
      fetchData(newParams);
    },
    [fetchData]
  );

  useEffect(() => {
    if (immediate) fetchData(params);
  }, []);

  return {
    loading: state.loading,
    data: state.data,
    error: state.error,
    fetch: fetchData,
    hasFetched:state.hasFetched,
    refetch,
  };
};

export default useFetch;
