'use client'
import { Portal, Transition } from '@headlessui/react'
import { MutableRefObject, ReactNode, Ref, forwardRef } from 'react'
import useDisableScroll from '../hooks/useDisableScroll'
import useOnClickOutside from '../hooks/useOnClickOutside'
import { clsx } from '../utils/clsx'
import { X } from './Icons'

type BottomSheetProps = {
  children: ReactNode
  show: boolean
  onClose?: Function
  title: string
  className?: string
}

const BottomSheet = forwardRef((props: BottomSheetProps, ref) => {
  const { children, show, onClose = () => {}, title, className = '' } = props
  useOnClickOutside(ref as MutableRefObject<HTMLElement>, () => {
    onClose()
  })
  useDisableScroll(show, true)
  return (
    <Portal>
      <div
        className={clsx(
          'w-screen h-full top-0 left-0 z-[999]',
          !show && 'hidden',
          show && 'fixed',
        )}
        style={{ backgroundColor: show ? 'rgba(0,0,0,0.5)' : 'transparent' }}
      >
        <Transition
          ref={ref as Ref<HTMLElement>}
          show={show}
          as="div"
          enter="transition ease duration-300 transform"
          enterFrom="opacity-0 translate-y-12"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease duration-150 transform"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-12"
          className={clsx(
            'bottom-sheet absolute left-0 border rounded rounded-t-lg border-gray-300 w-screen overflow-hidden flex flex-col z-100 bg-white',
            className,
          )}
        >
          <div className="bottom-sheet_title p-3 border-b border-gray-300 flex justify-between text-blue-brand text-md">
            <div className="font-medium">{title}</div>
            <X
              className="cursor-pointer"
              onClick={() => onClose()}
              weight="bold"
            />
          </div>
          <div className="bottom-sheet_children flex-1 max-h-full flex flex-col overflow-hidden">
            {children}
          </div>
        </Transition>
      </div>
    </Portal>
  )
})

BottomSheet.displayName = 'BottomSheet'
export default BottomSheet
