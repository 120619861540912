import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type CheckoutState = {
  isContinuingAsGuest: boolean;
  transitInsurance: boolean;
  currentStep: number;
  email: string | null;
  addressDetails: Record<any, any>;
  isCouponAccordionOpen:boolean
};

const initialState = {
  isContinuingAsGuest: false,
  transitInsurance: true,
  currentStep: 1,
  email: null,
  isCouponAccordionOpen:false
} as CheckoutState;

export const checkout = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    reset: () => initialState,
    setIsContinuingAsGuest: (state, action: PayloadAction<boolean>) => {
      state.isContinuingAsGuest = action.payload;
    },
    setTransitInsurance: (state, action: PayloadAction<boolean>) => {
      state.transitInsurance = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setAddressDetails: (state, action: PayloadAction<any>) => {
      state.addressDetails = action.payload;
    },
    setIsCouponAccordionOpen: (state, action: PayloadAction<boolean>) => {
      state.isCouponAccordionOpen = action.payload;
    },
    setDeliveryMethod:(state,action:PayloadAction<string>)=>{
      if(!state.addressDetails){
        state.addressDetails = {}
      }
      state.addressDetails.deliveryMethod = action.payload
    }
  },
});

export const {
  setIsContinuingAsGuest,
  reset,
  setTransitInsurance,
  setCurrentStep,
  setEmail,
  setAddressDetails,
  setDeliveryMethod,
  setIsCouponAccordionOpen
} = checkout.actions;

export default checkout.reducer;
