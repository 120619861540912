type ProductItem = {
  name: string | null;
  parent: {
    name: string | null;
  };
};
export const getProductName = (item: ProductItem) => {
  return (
    item.parent.name + (item.parent.name && item.name ? " - " : "") + item.name
  );
};

export const getPriceAfterPromotionalDiscount = (type:string,value:string,price:number)=>{
  if(type=='%'){
    return price - price*parseInt(value)/100 || 0;
  }
  return price-parseInt(value) || 0;
}
