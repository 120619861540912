'use client'
import { Menu } from '@headlessui/react'
import { Url } from 'next/dist/shared/lib/router/router'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { ReactNode, useEffect, useState } from 'react'
import { getPortalType } from '../utils/portal'
import { CaretDown } from './Icons'
import WithTransition from './hoc/WithTransition'

type DropdownItem = {
  title: string
  id: string | number
  href?: Url
}

type DropdownProp = {
  title: string
  items: Array<DropdownItem>
  onChange?: Function
  className?: string
  containerClassName?: string
  beforeItem?: ReactNode
  hover?: boolean
  dropdownClassName?: string
}

const Dropdown = (props: DropdownProp) => {
  const {
    title,
    items,
    onChange = (item: DropdownItem) => {},
    className = '',
    beforeItem,
    hover = false,
    containerClassName = '',
    dropdownClassName = '',
  } = props
  const [hovering, setIsHovering] = useState(false)
  const route = usePathname()
  useEffect(() => {
    setIsHovering(false)
  }, [route])
  const { isCFD, isLI, isCALIBO } = getPortalType()

  return (
    <div
      className={`relative ${containerClassName}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Menu>
        {({ open }) => (
          <div>
            <Menu.Button className={`flex relative items-center ${className}`}>
              <span>{title}</span>
              <CaretDown className="ml-2" />
            </Menu.Button>
            <div
              className={`absolute overflow-hidden min-w-[150px] top-11 dropdown-menu-container ${dropdownClassName}`}
            >
              <WithTransition
                show={hover ? hovering : open}
                className="mt-1 border border-gray-300 bg-white text-black rounded-lg overflow-hidden"
              >
                <Menu.Items static={hover} className="flex flex-col">
                  {beforeItem && <Menu.Item>{beforeItem}</Menu.Item>}
                  {items.map((item) => {
                    return (
                      <Menu.Item key={item.id}>
                        {({}) =>
                          item.href ? (
                            <Link
                              className={`p-2 ${
                                isCFD ? 'hover:bg-gray-200 hover:text-blue-700' : isLI ? 'hover:bg-green-100 hover:text-green-800' : isCALIBO ? 'calibo-dropdown-link' : ''
                              }`}
                              href={item.href}
                            >
                              {item.title}
                            </Link>
                          ) : (
                            <div
                              onClick={() => onChange(item)}
                              className={`p-2 ${
                                isCFD
                                  ? 'hover:bg-gray-200 hover:text-blue-700'
                                  : 'hover:bg-green-100 hover:text-green-800'
                              } cursor-pointer`}
                            >
                              {item.title}
                            </div>
                          )
                        }
                      </Menu.Item>
                    )
                  })}
                </Menu.Items>
              </WithTransition>
            </div>
          </div>
        )}
      </Menu>
    </div>
  )
}
export default Dropdown
