"use client";
import { Transition } from "@headlessui/react";
import { ReactNode } from "react";

type WithTransitionProps = {
  children: ReactNode;
  className?: string;
  show: boolean;
};

const WithTransition = (props: WithTransitionProps) => {
  const { show, className, children } = props;
  return (
    <Transition
      show={show}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      className={className}
    >
      {children}
    </Transition>
  );
};

export default WithTransition;
