'use client'
import { SidebarLink } from './Sidebar'
export const sidebarLinks: SidebarLink[] = [
  {
    id: 1,
    title: 'My Account',
    slug: '/account',
    description: 'Manage your personal information',
    color: '',
    items: [],
  },
  {
    id: 2,
    title: 'My Orders',
    slug: '/account/orders',
    description: 'Keep track of your purchases and order history',
    color: '',
    items: [],
  },
  {
    id: 3,
    title: 'My Activity ',
    slug: '#',
    description: 'Manage your wishlist and view your recently viewed items',
    color: '',
    items: [
      {
        id: 1,
        title: 'Wishlist ',
        slug: '/account/wishlist',
        color: '',
      },
      {
        id: 2,
        title: 'Recently Viewed ',
        slug: '/account/recently-viewed',
        color: '',
      },
    ],
  },
  {
    id: 4,
    title: 'Support',
    slug: '/account/contact-us',
    description: 'Get help and assistance with your orders and account',
    color: '',
    items: [],
  },
  {
    id: 5,
    title: 'Logout',
    slug: '/logout',
    description: 'Securely sign out of your account and protect your account',
    color: '',
    items: [],
  },
]
