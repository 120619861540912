import { API } from '../middleware/redirects';

export const REVALIDATE = process.env.NEXT_DEFAULT_CACHE_TTL
  ? parseInt(process.env.NEXT_DEFAULT_CACHE_TTL)
  : 3600
export async function FETCH(path: string, init?: RequestInit) {

  const next = init?.next || { revalidate: REVALIDATE };
  return await fetch([API, path].join('/'), {
    next,
    ...init,
  }).then((res) => res.json())
}
