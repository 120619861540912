'use client'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { LegacyRef, ReactNode, forwardRef, useEffect, useState } from 'react'
import { Models } from '../../../../../types/Models'
import { decodeCssString } from '../../../../../utils/css'
import { getPortalType } from '../../../../../utils/portal'
import Dropdown from '../../../../Dropdown'
import { CaretDown } from '../../../../Icons'
import useSlugEncoder from '../../hooks/useSlugEncoder'
import MenuItem from './Items/MenuItem'
import HeaderMegaMenu from './MegaMenu'

const NavigationTopBar = ({
  menu,
  subMenu,
}: {
  menu: Models.Menu
  subMenu?: Models.Menu
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const route = usePathname()
  const encoder = useSlugEncoder()
  const { isCALIBO } = getPortalType()
  useEffect(() => {
    setShowMenu(false)
  }, [route])
  return (
    <div className="justify-center items-center hidden md:flex nav-mega-menu-container h-full">
      <div className="max-w-page flex mx-auto w-full justify-between h-full">
        <div className="flex items-center flex-1">
          {menu?.items?.map((item, index) => {
            // @ts-ignore
            return (
              <MenuItem item={item} key={item.id} classNames={''}>
                <NavigationItem
                  onMouseEnter={() => {
                    if (index === 0) setShowMenu(true)
                  }}
                  onMouseLeave={() => {
                    if (index === 0) setShowMenu(false)
                  }}
                  className={`relative flex h-full items-center nav-item nav-type-${
                    item.type ? item.type : 'none'
                  }`}
                >
                  {index === 0 && !!item.children.length && (
                    <div className="py-2 px-3 flex items-center">
                      {item.image ? (
                        <Image
                          className="object-contain mr-3"
                          src={item.image.url}
                          height={22}
                          width={22}
                          alt={item.name}
                        />
                      ) : null}
                      <div className="text-left nav-item-link">{item.name}</div>
                      {isCALIBO && <CaretDown className="ml-2" />}
                      <HeaderMegaMenu
                        show={showMenu}
                        items={item.children}
                        onClose={() => setShowMenu(false)}
                      />
                    </div>
                  )}
                  {index !== 0 && !!item.children.length && (
                    <Dropdown
                      hover
                      containerClassName="p-2 h-full flex items-center"
                      dropdownClassName="!top-14"
                      beforeItem={
                        item.slug ? (
                          <Link
                            className={`p-2 underline ${isCALIBO ? 'text-blue-brand' : 'text-green-800'}`}
                            href={encoder(item)}
                          >
                            Shop all {item.name}
                          </Link>
                        ) : null
                      }
                      title={item.name}
                      items={item.children?.map((child) => ({
                        ...child,
                        title: child.name,
                        href: encoder(child),
                      }))}
                    />
                  )}
                  {index !== 0 && !item.children.length && (
                    <Link
                      style={{
                        ...decodeCssString(item.customisation.css as string),
                      }}
                      className="py-2 px-3 flex items-center"
                      href={encoder(item)}
                    >
                      {item.image ? (
                        <Image
                          className="object-contain mr-3"
                          src={item.image.url}
                          height={22}
                          width={22}
                          alt={item.name}
                        />
                      ) : null}
                      <div>{item.name}</div>{' '}
                    </Link>
                  )}
                </NavigationItem>
              </MenuItem>
            )
          })}
        </div>

        <div className="items-center hidden xl:flex">
          {subMenu?.items?.map((item) => {
            return (
              <NavigationItem
                key={item.id}
                className=" nav-item 2xl:text-base text-xs"
                customCSS={(item?.customisation?.css as string) || ''}
              >
                <Link
                  style={{
                    ...decodeCssString(item.customisation.css as string),
                  }}
                  className="py-2 2xl:px-2 px-1.5 flex"
                  href={encoder(item)}
                >
                  {item.image ? (
                    <Image
                      className="object-contain mr-3"
                      src={item.image.url}
                      height={22}
                      width={22}
                      alt="menu icon"
                    />
                  ) : null}
                  <div>{item.name}</div>
                </Link>
              </NavigationItem>
            )
          })}
        </div>

        {/*<div className="text-white items-center hidden lg:flex">*/}
        {/*  <Phone className="text-base"/>*/}
        {/*  <span className="ml-2 text-sm xl:text-base">*/}
        {/*    Need Help? Call{" "}*/}
        {/*    <a*/}
        {/*      className="underline underline-offset-2"*/}
        {/*      href={`tel:${CONTACT_NUMBER}`}*/}
        {/*    >*/}
        {/*      {CONTACT_NUMBER}*/}
        {/*    </a>{" "}*/}
        {/*  </span>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

type NavigationItemProp = {
  className?: string
  children: ReactNode
  onClick?: Function
  onMouseEnter?: Function
  onMouseLeave?: Function
  backgroundColor?: string
  customCSS?: string
}

const NavigationItem = forwardRef((props: NavigationItemProp, ref) => {
  const {
    onClick = () => {},
    onMouseEnter,
    onMouseLeave,
    customCSS = '',
  } = props
  return (
    <div
      ref={ref as LegacyRef<HTMLDivElement>}
      onClick={() => onClick()}
      onMouseEnter={() => onMouseEnter && onMouseEnter()}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      style={{
        ...decodeCssString(customCSS as string),
      }}
      className={`cursor-pointer transition-all duration-300 ease-in-out ${props.className}`}
    >
      {props.children}
    </div>
  )
})
NavigationItem.displayName = 'NavigationItem'
export default NavigationTopBar
