'use client'
import { Popover } from '@headlessui/react'
import { useAppSelector } from '@licommon/hooks/store'
import usePopupWishlist from '@licommon/hooks/whishlist/usePopupWishlist'
import Link from 'next/link'
import { Fragment, MutableRefObject, useRef } from 'react'
import useOnClickOutside from '../hooks/useOnClickOutside'
import { getPortalType } from '../utils/portal'
import Button from './Button'
import Divider from './Divider'
import WishlistItemPreview from './WishlistItemPreview'
import WithTransition from './hoc/WithTransition'

type ViewWishlistModalProps = {
  show: boolean
  onClose: Function
  containerRef?: MutableRefObject<any>
  className?: string
}

const WithModal = (props: ViewWishlistModalProps) => {
  const { show, onClose, containerRef, className } = props
  const ref = useRef(null)
  useOnClickOutside(containerRef || ref, onClose)
  return (
    <div ref={containerRef || ref} onMouseLeave={() => onClose()}>
      <Popover as={Fragment}>
        <WithTransition
          show={show}
          className={`absolute z-50 overflow-hidden top-[76px] -left-[200px] border border-gray-300 w-[400px] bg-white text-black border-t-0 md:rounded-b-md ${className}`}
        >
          <Popover.Panel static className="flex-1 flex">
            <WithoutModal />
          </Popover.Panel>
        </WithTransition>
      </Popover>
    </div>
  )
}

const WithoutModal = () => {
  const { removeProductFromWishlist } = usePopupWishlist()
  const items = useAppSelector((state) => state.wishlistPopup.products)
  const { isCFD } = getPortalType()

  const handleProductRemovedFromWishlist = async (productId: number) => {
    removeProductFromWishlist(productId)
  }

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <div className="flex flex-col flex-1 md:max-h-[50vh] overflow-y-auto">
        {items.map((item, index) => (
          <div key={item.name} className="p-3 flex flex-col">
            <WishlistItemPreview
              product={item}
              handleRemoveFromWishlist={handleProductRemovedFromWishlist}
            />
            {index + 1 !== items.length && <Divider className="my-1" />}
          </div>
        ))}
        {!items.length && (
          <div className="flex flex-col items-center justify-center flex-1 p-5">
            <div className="text-gray-500 text-lg font-medium">
              Your wishlist is empty
            </div>
            <Link href="/category">
              <Button className="mt-4" variant={isCFD ? 'blue' : 'success'}>
                Continue Shopping
              </Button>
            </Link>
          </div>
        )}
      </div>
      {!!items.length && (
        <div className="flex-col hidden md:flex">
          <Link
            href="/account/wishlist"
            className={`hidden md:flex bg-gray-100 text-center justify-center underline ${
              isCFD ? 'text-blue-700' : 'text-green-800'
            } p-2  text-sm font-medium border-t border-gray-300`}
          >
            See full Wishlist
          </Link>
        </div>
      )}
      {/* for mobile view */}
      <div className="flex flex-col md:hidden justify-end">
        <Divider />
        <div className="flex items-end mt-3">
          <Button
            href="/account/wishlist"
            className="flex-1 justify-center items-center m-3 mt-0"
            variant={isCFD ? 'blue' : 'success'}
          >
            <span>View Wishlist</span>
          </Button>
        </div>
      </div>
      {/* for mobile view */}
    </div>
  )
}

const ViewWishlistPreview = { Modal: WithModal, NoModal: WithoutModal }

export default ViewWishlistPreview
