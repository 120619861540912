"use client";

import { useEffect } from "react";

const useDisableScroll = (when: boolean, showOverlay = false) => {
  useEffect(() => {
    const overlay = document.getElementById("overlay");
    if (when) {
      document.body.classList.add("overflow-hidden");
      if (showOverlay && overlay) overlay.classList.remove("hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
      if (showOverlay && overlay) overlay.classList.add("hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
      if (showOverlay && overlay) overlay.classList.add("hidden");
    };
  }, [when, showOverlay]);
};

export default useDisableScroll;
