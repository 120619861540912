"use client";
import { clsx } from "../utils/clsx";
import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ArrowConfig, Arrow } from "./Accordion";

interface BottomBarProps {
  children?: React.ReactNode;
  className?: string;
  title: string;
  buttonClassName?: string;
  containerClassName?: string;
  headerClassName?: string;
  withTransition?: boolean;
  open?: boolean;
  disabled?: boolean;
  arrowConfig?: Omit<ArrowConfig, "isOpen">;
  onChange?: (value: boolean) => void;
}

const BottomBar = ({
  children,
  className,
  buttonClassName = "",
  containerClassName = "",
  headerClassName = "",
  title,
  withTransition = true,
  open = false,
  disabled = false,
  arrowConfig = {
    size: 18,
    className: "",
    position: "right",
  },
  onChange = () => {},
}: BottomBarProps) => {
  const [isOpen, setIsOpen] = useState(open ?? false);

  useEffect(() => {
    if (open === isOpen) return;
    setIsOpen(open);
  }, [open]);

  const handleToggleBar = () => {
    if (disabled) return;
    const update = !isOpen;
    setIsOpen(update);
    onChange(update);
  };

  return (
    <div
      className={clsx(
        "bottom-bar border-t border-gray-300 bg-white w-full z-[99] px-[32px] py-[24px] fixed bottom-0",
        className
      )}
    >
      <div
        onClick={handleToggleBar}
        className={clsx(
          "bottom-bar-button flex w-full items-center gap-[24px] cursor-pointer",
          buttonClassName
        )}
      >
        {arrowConfig.position === "left" && (
          <Arrow {...arrowConfig} isOpen={isOpen} />
        )}
        <h2 className={clsx("bottom-bar-header", headerClassName)}>{title}</h2>
        {arrowConfig.position === "right" && (
          <Arrow {...arrowConfig} isOpen={isOpen} />
        )}
      </div>

      {withTransition ? (
        <Transition
          show={isOpen}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={clsx("accordion-body", containerClassName)}>
            {children}
          </div>
        </Transition>
      ) : (
        <div className={clsx("accordion-body", containerClassName)}>
          {children}
        </div>
      )}
    </div>
  );
};

export default BottomBar;
