export function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const formattedDate = date
        .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        })
        .replace(/ /g, "-");
    return formattedDate;
}

export function dateIsPassed(dateString: string): boolean {
    const utcDate = new Date(dateString);
    const comparableDate = new Date(dateString);
    comparableDate.setUTCFullYear(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate());
    comparableDate.setUTCHours(utcDate.getHours());
    const today = new Date();
    // console.log(comparableDate);
    // console.log(today);
    return comparableDate < today;
}

export function dateIsToday(dateString: string): boolean {
    const date = new Date(dateString);
    const today = new Date();
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
}