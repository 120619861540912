import { getSession } from 'next-auth/react';
export const ACCESS_TOKEN_KEY = 'access_token'

export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

export const getAccessToken = (): string | null => {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
}

export const fetchAndSetToken = async() =>{
  return new Promise(async (resolve, reject) => {
  const session = await getSession()
  if(session){
    setAccessToken(session?.user?.token)
  }
  resolve(true)
})
}