"use client";

import { MutableRefObject, RefObject, useEffect } from "react";

function useOnClickOutside(ref: MutableRefObject<any>, onClick: Function) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        onClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClick]);
}

export default useOnClickOutside;
