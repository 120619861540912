'use client'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { ReactNode, useEffect, useRef, useState } from 'react'
import BottomSheet from '../../../../../components/BottomSheet'
import DoAuthPreview from '../../../../../components/DoAuthPreview'
import {
  MagnifyingGlass,
  ShoppingCart,
  Star,
  Storefront,
  TextAlignJustify,
  UserCircle,
  X,
} from '../../../../../components/Icons'
import NotificationBubble from '../../../../../components/NotificationBubble'
import ViewCartPreview from '../../../../../components/ViewCartPreview'
import ViewWishlistPreview from '../../../../../components/ViewWishlistPreview'
import useDisableScroll from '../../../../../hooks/useDisableScroll'
import { useAppSelector } from '@licommon/hooks/store/hooks'
import { getPortalType } from '../../../../../utils/portal'
import AccountActionPreview from '../../../../AccountActionsPreview'
import Sidebar from '../../../sidebar/Sidebar'
import { sidebarLinks } from '../../../sidebar/sidebarLinks'

type NavigationTopBarProps = {
  children: ReactNode
  showSidebar: boolean
  onCloseSidebar: Function
  onOpenSidebar: Function
  onShowSearch: Function
  showStore?: boolean
}

const NavigationTopBar = ({
  children,
  showSidebar,
  onOpenSidebar,
  onCloseSidebar,
  onShowSearch,
  showStore = false,
}: NavigationTopBarProps) => {
  useDisableScroll(showSidebar)

  const [showAuthModal, setShowAuthModal] = useState(false)
  const authModalRef = useRef(null)
  const [showAccountModal, setShowAccountModal] = useState(false)
  const accountModalRef = useRef(null)
  const [showCartModal, setShowCartModal] = useState(false)
  const cartModalRef = useRef(null)
  const [showWishlistModal, setShowWishlistModal] = useState(false)
  const wishlistRef = useRef(null)

  const cartItemsCount = useAppSelector((state) => state.cart.noOfItems)
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn)
  const wishItemCount = useAppSelector((state) => state.wishlistPopup.total)

  const url = usePathname()
  const router = useRouter()
  useEffect(() => {
    setShowAuthModal(false)
    setShowCartModal(false)
    setShowWishlistModal(false)
    setShowAccountModal(false)
  }, [url])
  const user = useAppSelector((state) => state.auth.details)
  const { isCFD, isCALIBO, isLI } = getPortalType()
  const logo = isCALIBO ? '/calibo-logo-blue-wide.png' : '/logo-white.png'
  const alt = isCALIBO
    ? 'Calibo Logo'
    : isCFD
    ? 'Ceiling Fans Direct Logo'
    : 'Lighting Illusion Logo'

  return (
    <>
      <div className="p-3 bg-blue-700 text-white header-topbar">
        {isCALIBO && (
          <div className="cursor-pointer flex items-center">
            {!showSidebar && (
              <TextAlignJustify
                className="text-3xl"
                onClick={() => onOpenSidebar()}
              />
            )}
            {showSidebar && (
              <X className="text-3xl" onClick={() => onCloseSidebar()} />
            )}
            <Link href="/" className="flex justify-center items-center ml-2">
              <Image
                src={logo}
                width={0}
                height={0}
                alt={alt}
                sizes="100vw"
                className="site-logo"
                style={{ width: 'auto', height: '36px' }}
              />
            </Link>
          </div>
        )}
        {!isCALIBO && (
          <div className="flex justify-between text-[1.75rem] items-center">
            <div className="cursor-pointer flex items-center">
              {!showSidebar && (
                <TextAlignJustify
                  className="text-3xl"
                  onClick={() => onOpenSidebar()}
                />
              )}
              {showSidebar && (
                <X className="text-3xl" onClick={() => onCloseSidebar()} />
              )}
              <Link href="/" className="flex justify-center items-center ml-2">
                <Image
                  src={logo}
                  width={0}
                  height={0}
                  alt={alt}
                  sizes="100vw"
                  style={{ width: 'auto', height: '36px' }}
                />
              </Link>
            </div>
            <div className="flex">
              <MagnifyingGlass
                onClick={() => onShowSearch()}
                className="mr-3 ml-2"
              />
              {showStore && (
                <Storefront
                  className="mr-3"
                  onClick={() => {
                    router.push('/store')
                  }}
                />
              )}
              <div className="relative">
                <Star
                  onClick={() => setShowWishlistModal(true)}
                  className="mr-3"
                />
                {wishItemCount > 0 && (
                  <NotificationBubble size="sm" count={wishItemCount} />
                )}
              </div>
              <UserCircle
                onClick={() => {
                  if (isLoggedIn) {
                    setShowAccountModal(true)
                  } else {
                    setShowAuthModal(true)
                  }
                }}
                className="mr-3"
              />
              <div className="relative">
                <ShoppingCart onClick={() => setShowCartModal(true)} />
                {cartItemsCount > 0 && (
                  <NotificationBubble size="sm" count={cartItemsCount} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {children}
      {!isCALIBO && (
        <>
          <BottomSheet
            title="Login"
            onClose={() => setShowAuthModal(false)}
            ref={authModalRef}
            show={showAuthModal}
          >
            <DoAuthPreview.NoModal />
          </BottomSheet>
          <BottomSheet
            title="Account Menu"
            onClose={() => setShowAccountModal(false)}
            ref={accountModalRef}
            show={showAccountModal}
          >
            {isCFD && (
              <div className="px-4 overflow-scroll">
                <Sidebar
                  userName={user?.first_name}
                  sidebarLinks={sidebarLinks}
                  showCaretBtn={false}
                  containerClass="bg-gray-100  rounded-lg "
                  innerContainer="flex-col  gap-3 !items-start"
                  linkClass="pl-2 text-blue-700 underline font-semibold"
                  subLinkClass="pl-5 !text-blue-700 underline font-semibold"
                />
              </div>
            )}
            {isLI && <AccountActionPreview.NoModal />}
          </BottomSheet>
          <BottomSheet
            title={`My Cart(${cartItemsCount})`}
            ref={cartModalRef}
            onClose={() => setShowCartModal(false)}
            show={showCartModal}
          >
            <ViewCartPreview.NoModal />
          </BottomSheet>
          <BottomSheet
            title={`My WishList(${wishItemCount})`}
            ref={wishlistRef}
            onClose={() => setShowWishlistModal(false)}
            show={showWishlistModal}
          >
            <ViewWishlistPreview.NoModal />
          </BottomSheet>
        </>
      )}
    </>
  )
}

export default NavigationTopBar
