'use client'
import { TProductCard } from '@licommon/components/product-card'
import { RootState } from '@licommon/hooks/store'
import { setIds, setProducts, setTotal } from '@licommon/hooks/store/features/popupWishlistSlice'
import { getSessionToken } from '@licommon/utils/session'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Paginated } from '../../types'
import { alertSuccess, showSuccess } from '../../utils/alert'
import useFetch from '../useFetch'
import usePost from '../usePost'
const usePopupWishlist = () => {
  const dispatch = useDispatch()
  const wishlist = useSelector((state: RootState) => state.wishlistPopup)

  const router = useRouter()
  const { loading: removing, post: removeProduct } = usePost(
    'api.customer.add-wishlist',
    { siteId: process.env.NEXT_PUBLIC_SITE_ID },
  )

  const {
    loading,
    data,
    fetch: getProducts,
  } = useFetch<{ products: Paginated<TProductCard[]>; ids: number[] }>(
    'api.customer.get-wish-products',
    {
      session: getSessionToken(),
      page: 1,
      per_page: 6,
      siteId: process.env.NEXT_PUBLIC_SITE_ID,
    },
    false,
  )

  const setData = (res: {
    products: Paginated<TProductCard[]>
    ids: number[]
  }) => {
    if (res?.products) {
      dispatch(setProducts(res.products.data))
      dispatch(setTotal(res.products.total))
      dispatch(setIds(res.ids))
    }
  }

  useEffect(() => {
    if (data?.products) {
      dispatch(setProducts(data.products.data))
      dispatch(setTotal(data.products.total))
      dispatch(setIds(data.ids))
    }
  }, [data])

  async function removeProductFromWishlist(productId: number) {
    await removeProduct({
      productId: productId,
    })
    if (!wishlist.ids.includes(productId)) {
      showSuccess('Product added to your wishlist!')
    } else {
      alertSuccess('Product removed from your wishlist!')
    }
    const res = await getProducts()
    setData(res)
  }

  return { loading, getProducts, removing, removeProductFromWishlist }
}

export default usePopupWishlist
