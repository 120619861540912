import { doPost } from '../api-client'

const handleLogin = async (
  account: any,
  profile: any,
  session_token?: string,
) => {
  const fullNameArray = profile?.name?.split(' ')
  console.log('SESSIONSSNNSNNSNSNSNSSNS')
  console.log(session_token)
  console.log('SESSIONSSSSSSSSSSSSSSSSSSSS')
  const data = {
    email: profile.email,
    accessToken: account.access_token,
    provider: account.provider,
    social_id: profile.id ? profile.id : profile.sub,
    session_token,
    firstName:
      fullNameArray?.length > 1
        ? fullNameArray.slice(0, -1).join(' ')
        : profile?.name,
    lastName:
      fullNameArray?.length > 1 ? fullNameArray.slice(-1).join(' ') : '',
  } as {
    email: string
    accessToken: string
    provider: string
    social_id: string
    firstName?: string
    lastName?: string
  }

  const response = await doPost('api.social-login', data)
  return response
}

const handleSignup = async (
  account: any,
  profile: any,
  session_token?: string,
) => {
  const fullNameArray = profile.name ? profile.name?.split(' ') : []

  const data = {
    firstName:
      fullNameArray?.length > 1
        ? fullNameArray.slice(0, -1).join(' ')
        : profile?.name ?? '',
    lastName:
      fullNameArray?.length > 1 ? fullNameArray.slice(-1).join(' ') : '',
    email: profile.email,
    accessToken: account.access_token,
    provider: account.provider,
    social_id: profile.id ? profile.id : profile.sub,
    session_token,
  }
  const response = await doPost('api.social-register', data)
  return response
}

const handleSocialAuth = async (
  account: any,
  profile: any,
  authType: string,
  session_token?: string,
) => {
  if (authType === 'login') {
    return await handleLogin(account, profile, session_token)
  } else {
    return await handleSignup(account, profile, session_token)
  }
}

export default handleSocialAuth
