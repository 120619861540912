import { UserData } from "@licommon/types/User";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  isLoggedIn: boolean;
  details: UserData | null;
};

const initialState = {
  isLoggedIn: false,
  details: null,
} as AuthState;

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => initialState,
    setUser: (state, action: PayloadAction<UserData | null>) => {
      state.details = action.payload;
      if (action.payload) state.isLoggedIn = true;
      else state.isLoggedIn = false;
    },
  },
});

export const { setUser, reset } = auth.actions;
export default auth.reducer;
