import { CartSummary } from '@licommon/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
const initialState = {
  couponCode: null,
  coupons: [],
  details: {},
  errorMessage: null,
  finalTotalAmount: 0,
  gstAmount: 0,
  isCartChanged: false,
  newCoupons: [],
  noOfItems: 0,
  orderId: 0,
  products: [],
  rrpCoupon: false,
  session_token: '',
  shippingAmount: 0,
  shippingPromotionDiscount: 0,
  subTotal: 0,
  subTotalPromotionDiscount: 0,
  totalDiscount: 0,
  totalPrice: 0,
  totalPromotionDiscount: 0,
  transitInsurance: 0,
  valid: false,
  loading: false,
  isFetching: false,
  surchargeAmount: 0,
  totalAmountWithSurcharge: 0,
  restoreCart: null,
  originalShippingAmount: 0,
  originalTransitInsurance: 0,
} as CartSummary & {
  loading: boolean
  isFetching: boolean
  surchargeAmount: number
  totalAmountWithSurcharge: number
  restoreCart: string | null
  originalShippingAmount: number
  originalTransitInsurance: number
}

export const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    reset: () => initialState,
    setCartData: (state, action: PayloadAction<CartSummary>) => {
      const {shippingAmount, transitInsurance, deliveryMethod } = action.payload
      if(deliveryMethod !='pickup'){
         state.originalShippingAmount = shippingAmount
         state.originalTransitInsurance = transitInsurance
      }
      Object.assign(state, action.payload)
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setRestoreCart: (state, action: PayloadAction<string>) => {
      state.restoreCart = action.payload
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload
    },
    addSurcharge: (state, action: PayloadAction<number>) => {
      const amount = state.finalTotalAmount * (action.payload / 100)
      state.surchargeAmount = amount
      state.totalAmountWithSurcharge = state.finalTotalAmount + amount
    },
  },
})
export const {
  setCartData,
  reset,
  setLoading,
  setIsFetching,
  addSurcharge,
  setRestoreCart,
} = cart.actions

export default cart.reducer
