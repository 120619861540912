import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {TProductCard} from "../../components";

export type TInitialState = {
    products: TProductCard[];
    currentPage: number;
    lastPage: number;
    total: number;
    perPage: number;
}

const initialState: TInitialState = {
    products: [],
    currentPage: 1,
    lastPage: 1,
    perPage: 30,
    total: 0,
}

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        setProducts: (state, action: PayloadAction<TProductCard[]>) => {
            state.products = action.payload;
        },
        goToPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },

        removeProductFromWishlist: (state, action: PayloadAction<number>) => {
            state.products = state.products.filter((product) => product.id !== action.payload);
        },
        setPagination: (state, action: PayloadAction<{lastPage: number, total: number, perPage: number}>) => {
            state.lastPage = action.payload.lastPage;
            state.total = action.payload.total;
            state.perPage = action.payload.perPage;
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        setPerPage: (state, action: PayloadAction<number>) => {
            state.perPage = action.payload;
        },
        reset: () => initialState,
    }
});


export const {setProducts, removeProductFromWishlist, goToPage, setPagination, setCurrentPage, setPerPage,reset} = wishlistSlice.actions;
export default wishlistSlice.reducer;
