'use client'
import { doGet } from '@licommon/authOptions'
import { setUser } from '@licommon/hooks/store/features/authSlice'
import { useAppSelector } from '@licommon/hooks/store/hooks'
import usePopupWishlist from '@licommon/hooks/whishlist/usePopupWishlist'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useHandleLogout from '../hooks/auth/useHandleLogout'
import useRefetchCart from '../hooks/cart/useRefetchCart'
import { UserData } from '../types'
import { fetchAndSetToken, getAccessToken } from '../utils/token'

const GlobalDataSetter = () => {
  /* set user details */
  const session = useSession()
  const dispatch = useDispatch()
  const handleLogout = useHandleLogout()
  const { getProducts: getWishlistProducts } = usePopupWishlist()

  const fetchCustomerInfo = async () => {
    await fetchAndSetToken()
    doGet<{ data: UserData; message: string }>('api.customer.get-info')
      .then((data) => {
        if (data.message.includes('Unauthenticated')) {
          throw Error()
        }
        dispatch(setUser(data.data))
      })
      .catch(() => {
        handleLogout()
      })
  }

  useEffect(() => {
    if (session?.data) {
      fetchCustomerInfo()
    }
  }, [session?.data?.user?.token])
  /* set user details */

  /* set cart/wishlist details */
  const user = useAppSelector((state) => state.auth.details)
  const fetchCart = useRefetchCart()

  useEffect(() => {
    if (user) {
      getWishlistProducts()
      if (!getAccessToken()) {
        fetchAndSetToken()
      }
    }
  }, [user])

  useEffect(() => {
    fetchCart()
  }, [user])
  /* set cart/wishlist cart */
  return <></>
}

export default GlobalDataSetter
