import { default as ziggyRoute } from 'ziggy-js';


const ziggy = ({} = {}) => {

    const BACKEND_API_URL = process.env.NEXT_PUBLIC_BACKEND_API_URL;
    const ZIGGY_API_ENDPOINT = "api/ziggy"; // TODO: pull this in from the apps process.env
    const routes = async () => {
        const response = await fetch(`${BACKEND_API_URL}/${ZIGGY_API_ENDPOINT}`, {cache:'force-cache'});
        return await response.json();
    }

    const route = async (name: string, params: object | null | undefined = null, absolute: boolean = true, config: object | null | undefined = null) => {
        return ziggyRoute(name, params, absolute, config ?? await routes())
    }

    return {
        // exports
        config: {
            host: BACKEND_API_URL
        },
        routes,
        route,
    }
};
export default ziggy();
