import { useAppDispatch } from '@licommon/hooks/store'
import { setUser } from '@licommon/hooks/store/features/authSlice'
import { reset as resetCart } from '@licommon/hooks/store/features/cartSlice'
import { reset as resetWishlistPopup } from '@licommon/hooks/store/features/popupWishlistSlice'
import { reset as resetWishlist } from '@licommon/hooks/store/features/wishlistSlice'
import { removeSessionToken } from '@licommon/utils/session'
import { signOut } from 'next-auth/react'
import { usePathname, useRouter } from 'next/navigation'
import { useCallback } from 'react'
import { showSuccess } from '../../utils/alert'
import { removeAccessToken } from '../../utils/token'
const useHandleLogout = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const pathname = usePathname()
  return useCallback(async (shouldAlert = true) => {
    return new Promise(async (resolve) => {
      await signOut({ redirect: false })
      if (pathname.includes('/account') || pathname.includes('/wishlist')) {
        router.replace('/')
      }
      dispatch(setUser(null))
      removeSessionToken()
      dispatch(resetCart())
      dispatch(resetWishlist())
      dispatch(resetWishlistPopup())
      localStorage.removeItem('checkout')
      removeAccessToken()
      if (shouldAlert) showSuccess('Logged out successfully!')
      resolve(true)
    })
  }, [])
}

export default useHandleLogout
