'use client'

import Image from 'next/image'
import Link from 'next/link'
import { useCallback, useState, useEffect } from 'react'
import { Models } from '../../../../../../types/Models'
import { decodeCssString } from '../../../../../../utils'
import { CaretRight } from '../../../../../Icons'
import useSlugEncoder from '../../../hooks/useSlugEncoder'
import MenuItem from './MenuItem'

type HeaderMegaMenuProps = {
  item: Models.MenuItem
  setActiveItem?: (item: Models.MenuItem, active: boolean) => void
  showCaret?: boolean
}

const MenuItemLink = ({
  item,
  showCaret = true,
  setActiveItem = () => {},
}: HeaderMegaMenuProps) => {
  const [active, setActive] = useState(false)
  const encodeSlug = useSlugEncoder()
  const handleSetActive = useCallback(
    (active: boolean) => {
      setActiveItem(item, active)
    },
    [active],
  )

  const [childDisabled, setChildDisabled] = useState(false)

  useEffect(() => {
   const childDisabled =  item.children.length > 0 ?
     item.children.every((child) => child?.linked?.disabled) : false
   setChildDisabled(childDisabled)
}, [])

  return (
    <div
      key={item.id}
      style={{}}
      className={`mega-menu__item dropdown-nav-row flex-1 2xl:flex-none ${
        active ? 'mega-menu__item:active' : ''
      }`}
    >
      <MenuItem item={item}>
        <Link
          href={encodeSlug(item)}
          onMouseEnter={(event) => handleSetActive(true)}
          onMouseLeave={() => handleSetActive(false)}
          className="dropdown-nav-item item-link"
          data-children={item.id}
          style={{
            ...decodeCssString(item.customisation.css as string),
          }}
        >
          <div className="flex items-center">
            {item.image ? (
              <Image
                className="object-contain mr-3"
                src={item.image.url}
                height={22}
                width={22}
                alt={item.name}
              />
            ) : null}
            <span>{item.name}</span>
          </div>
          <span>{!!item.children.length && showCaret && !childDisabled && <CaretRight />}</span>
        </Link>
      </MenuItem>
    </div>
  )
}

export default MenuItemLink
