'use client'

import Monaco from '@monaco-editor/react'

export function Env(props: { value: NodeJS.ProcessEnv }) {
  const value = JSON.stringify(
    Object.fromEntries(
      Object.entries(props.value).filter(
        ([k, v]) => !(k.includes('TOKEN') || k.includes('KEY')),
      ),
    ),
    null,
    2,
  )

  return (
    <Monaco
      {...{ value }}
      language="json"
      theme="vs-dark"
      width={'100%'}
      height={'100vh'}
      options={{ wordWrap: 'on' }}
    />
  )
}
