'use client'

import { Menu } from '@headlessui/react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { MutableRefObject } from 'react'
import RatingStars from '../../../../components/RatingStars'
import SectionLoading from '../../../../components/SectionLoading'
import WithTransition from '../../../../components/hoc/WithTransition'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import { Label } from '../../../../types'
import { ParentAttribute, Product } from '../../../../types/Product'
import ProductCategory from '../../../../types/ProductCategory'
import { getPriceAfterPromotionalDiscount } from '../../../../utils'
import { clsx } from '../../../../utils/clsx'
import { formatCurrency } from '../../../../utils/currency'
import { getPortalType } from '../../../../utils/portal'
import { Category } from '../../../category/types/Category'
import { TProductRangeData } from '../../../range/ProductRangeInner'

type HeaderSearchModalProps = {
  show: boolean
  onClose: Function
  containerRef: MutableRefObject<any>
  searchTerm: string
  products: Product[]
  ranges: TProductRangeData[]
  categories: ProductCategory[]
  loading: boolean
}

const HeaderSearchModal = (props: HeaderSearchModalProps) => {
  const {
    show,
    onClose,
    containerRef,
    products,
    categories,
    searchTerm,
    loading,
    ranges,
  } = props
  useOnClickOutside(containerRef, () => onClose())
  const router = useRouter()
  const { isCALIBO } = getPortalType()
  return (
    <Menu>
      <WithTransition
        show={show && searchTerm.length > 0}
        className="absolute z-100 top-18 2xl:top-16 mx-auto my-auto left-0 right-0 md:border border-gray-300 bg-white md:rounded-lg cursor-auto text-blue-brand overflow-hidden w-[100vw] md:w-[80vw] xl:w-[70vw] 2xl:w-full"
      >
        <Menu.Items static className="flex w-100 flex-col lg:flex-row">
          <div className="hidden lg:flex flex-col p-4 border-r border-gray-300 min-w-[256px] search-results-categories md:max-h-[30vh] lg:max-h-[60vh] overflow-auto">
            {categories.length === 0 && !loading && (
              <div>No Categories Matched</div>
            )}
            {categories.map((category, index) => {
              return (
                <Link
                  key={category.id}
                  href={`/category/${category.slug}`}
                  className={clsx(
                    'font-bold cursor-pointer',
                    index != categories.length - 1 && 'mb-3',
                    isCALIBO &&
                      'font-medium text-base capitalize underline text-blue-brand',
                  )}
                >
                  <HighlightedSearch
                    search={searchTerm}
                    title={category.label || category.name}
                  />
                </Link>
              )
            })}
          </div>
          <div className="pt-0 flex-1 bg-gray-100 search-results-products md:max-h-[40vh] lg:max-h-[60vh] overflow-auto">
            {/* no products found (cfd / li) */}
            {!isCALIBO && products.length === 0 && !loading && (
              <div className="p-4">No matching products found</div>
            )}
            {/* no ranges found (calibo) */}
            {isCALIBO && ranges.length === 0 && !loading && (
              <div className="p-4 text-gray-500">
                No matching product ranges found
              </div>
            )}

            {/* Search Results Cards loop */}
            {((!isCALIBO && products.length > 0) ||
              (isCALIBO && ranges.length > 0)) &&
              !loading && (
                <>
                  <div className="font-medium justify-between flex sticky top-0 py-2 px-4 pt-3 z-100 bg-gray-100">
                    <span>Suggested for you</span>
                    <span
                      onClick={() => {
                        router.push(`/search/${searchTerm}`)
                      }}
                      className={`${isCALIBO ? 'text-blue-brand' : 'text-primary'} underline cursor-pointer`}
                    >
                      View all results
                    </span>
                  </div>
                  <div className="px-4 pb-4">
                    {/* CFD & LI - returning product cards */}
                    {!isCALIBO &&
                      products?.map((product) => {
                        const {
                          document: { content, id },
                          model,
                        } = product
                        return (
                          <div key={id}>
                            <SearchCard
                              title={`${content.parent_product_name}${
                                content.parent_product_name ? ' - ' : ''
                              }${content.name}`}
                              labelDetails={content?.label_details}
                              price={content.sale_price}
                              originalPrice={content.price}
                              image={content.thumbnail.url}
                              rating={content.average_rating}
                              slug={content.slug}
                              categories={content.categories}
                            />
                          </div>
                        )
                      })}

                    {/* CALIBO - returning range cards */}
                    {isCALIBO &&
                      ranges?.map((range) => {
                        console.log(range)
                        return (
                          <div key={range.id}>
                            <SearchCard
                              title={range.name ?? ''}
                              labelDetails={undefined}
                              price={range.price ?? null}
                              originalPrice={range.price ?? null}
                              image={range.thumbnail.url ?? null}
                              rating={1}
                              slug={range.slug}
                              categories={[]}
                            />
                          </div>
                        )
                      })}
                  </div>
                </>
              )}
          </div>
          {loading && <SectionLoading />}
        </Menu.Items>
      </WithTransition>
    </Menu>
  )
}

const HighlightedSearch = ({
  search,
  title,
}: {
  search: string
  title: string
}) => {
  const { isCFD } = getPortalType()
  search = search.toLowerCase()
  title = title.toLowerCase()
  if (title.includes(search)) {
    const index = title.indexOf(search)
    const keys = [
      title.slice(0, index),
      title.slice(index, index + search.length),
      title.slice(index + search.length, title.length),
    ]
    return keys
      .filter((k) => k.length > 0)
      .map((k) => (
        <span
          key={k}
          className={clsx(
            k === search &&
              `font-normal ${isCFD ? 'text-blue-700' : 'text-green-700'}`,
            k !== search && 'font-semibold',
          )}
        >
          {k}
        </span>
      ))
  }
  const searchSplits = search.split(' ')
  return title.split(' ').map((label) => {
    return searchSplits.includes(label) ? (
      <span key={label} className="font-normal text-green-700">
        {label + ' '}
      </span>
    ) : (
      label + ' '
    )
  })
}

type SearchCardProps = {
  title: string
  price: number
  originalPrice: number
  rating: number
  image: string
  slug: string
  labelDetails: Label | undefined
  categories: Category[]
}

const SearchCard = (props: SearchCardProps) => {
  const {
    title,
    price,
    originalPrice,
    rating,
    image,
    slug,
    labelDetails,
    categories,
  } = props

  // only categories which no repeat
  const filteredCategories = categories.filter(
    (category, index, self) =>
      index === self.findIndex((t) => t.slug === category.slug) &&
      (!category?.depth || category?.depth > -1) &&
      category.parent_id != null,
  )

  const displayPrice =
    labelDetails && labelDetails?.apply_promotion
      ? getPriceAfterPromotionalDiscount(
          labelDetails?.type,
          labelDetails?.value,
          price,
        )
      : price
  const discount = originalPrice - displayPrice
  const { isCALIBO } = getPortalType()
  return (
    <Link
      href={`${isCALIBO ? `/range/${slug}` : `/product/${slug}`}`}
      className={`${isCALIBO ? 'text-base' : 'text-sm'} text-ellipsis font-medium"`}
    >
      <div
        className={`w-100 shadow-sm border border-gray-300 my-2 rounded-md flex overflow-hidden bg-white`}
      >
        <div className="mr-2 h-100 flex items-center justify-center flex-shrink-0">
          <Image
            src={image || ''}
            width={120}
            height={120}
            className={`${isCALIBO ? 'object-cover' : 'object-contain'}`}
            alt={title}
            loading="eager"
            priority={true}
          />
        </div>
        <div className="flex flex-col p-3">
          <div
            className={`${isCALIBO ? 'text-xl text-gray-900' : 'text-sm'} text-ellipsis font-medium line-clamp-3`}
          >
            {title}
          </div>
          <div className="flex items-center">
            <div
              className={`${isCALIBO ? 'text-base text-gray-500' : 'text-xs text-gray-900 font-semibold'}`}
            >
              {!isCALIBO &&
                  formatCurrency(displayPrice)}
            </div>
            {!isCALIBO && discount && (
              <div
                className={`${isCALIBO ? 'text-base' : 'text-xxs'} font-medium text-red-600 ml-2`}
              >
                Save {formatCurrency(discount)}
              </div>
            )}
          </div>
          {!isCALIBO && rating > 0 ? (
            <div className="mt-1 mb-2">
              <RatingStars readOnly={true} value={rating} />
            </div>
          ) : null}
          <div
            className={`${isCALIBO ? 'text-sm' : 'text-xs'} flex flex-1 items-end gap-2 flex-wrap underline font-medium`}
          >
            {filteredCategories &&
              filteredCategories.slice(0, 2).map((category) => {
                return (
                  <Link
                    key={category.id}
                    href={`/category/${category.slug}`}
                    className=""
                  >
                    {category.label}{' '}
                  </Link>
                )
              })}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default HeaderSearchModal
