'use client'
import { useRouter } from 'next/navigation'
import { ChangeEventHandler, LegacyRef, ReactNode, forwardRef } from 'react'
import { MagnifyingGlass } from '../../../../../components/Icons'
import { clsx } from '../../../../../utils'
import { getPortalType } from '../../../../../utils/portal'

const { isLI, isCALIBO, isCFD } = getPortalType()

export const SEARCH_PLACEHOLDER =
  isLI ? `Search 1000's of Lights, Fans & More`
    : isCFD ? `Search 1000's of FAN-tastic Deals` 
    : isCALIBO ? 'Search our product ranges'
    : 'Search...';

export const SearchBar = forwardRef(
  (
    {
      onShow,
      children,
      value,
      onChange,
      onClose = () => {},
      searchModalClasses,
    }: {
      children: ReactNode
      onShow: Function
      onChange: ChangeEventHandler<HTMLInputElement>
      value: string
      onClose?: Function
      searchModalClasses?: string
    },
    ref,
  ) => {
    const router = useRouter()
    return (
      <div
        ref={ref as LegacyRef<HTMLDivElement>}
        className={clsx(searchModalClasses, ' w-full h-full')}
      >
        <div className=" p-2 relative text-gray-500 w-full h-full">
          <div className="absolute search-icon">
            <MagnifyingGlass className="text-xl" />
          </div>
          <input
            onKeyDown={(e) => {
              if (e.key == 'Escape') {
                if (document.activeElement)
                  (document.activeElement as HTMLElement).blur()
                onClose()
              }
              if (e.key == 'Enter') {
                router.push(`/search/${value}`)
              }
            }}
            value={value}
            onChange={onChange}
            onClick={() => onShow()}
            placeholder={SEARCH_PLACEHOLDER}
            className="nav-search first-letter:uppercase w-full h-[44px]"
          />
        </div>
        {children}
      </div>
    )
  },
)
