'use client'
import Image from 'next/image'
import Link from 'next/link'
import { ReactNode, useMemo, useState } from 'react'
import Divider from '../../../../../components/Divider'
import { ArrowLeft, CaretRight } from '../../../../../components/Icons'
import { NavigationLink } from '../../../../../consts/mock'
import { Models } from '../../../../../types/Models'
import { decodeCssString } from '../../../../../utils'
import { clsx } from '../../../../../utils/clsx'
import { getPortalType } from '../../../../../utils/portal'
import MenuItemCTA from '../../../header/desktop/components/Items/MenuItemCTA'
import useSlugEncoder from '../../hooks/useSlugEncoder'

const findProduct = (
  id: number,
  items: Models.MenuItems,
): null | Models.MenuItem => {
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    if (item.id === id) return item
    if (item.children) {
      let product = findProduct(id, item.children)
      if (product) return product
    }
  }
  return null
}
export type NavigationCategoriesProps = {
  menu: Models.Menu
  subMenu?: Models.Menu
  onSelect: Function
  contactNumber: string
  emailAddress?: string
}

const NavigationCategories = ({
  menu,
  subMenu,
  onSelect,
  contactNumber,
  emailAddress,
}: NavigationCategoriesProps) => {
  const { isCFD, isCALIBO } = getPortalType()
  const encoder = useSlugEncoder()
  return (
    <>
      <div className="flex-1 overflow-y-auto mobile-nav-child-items">
        {menu?.items?.map((item, index) => (
          <NavItem key={item.id} onSelect={() => onSelect(item)} item={item} />
        ))}
      </div>
      {!isCALIBO && (
        <>
          <Divider className="my-5" />
          <div
            className={`flex items-center justify-center ${
              isCFD
                ? 'bg-gray-200 text-blue-700'
                : 'bg-green-100 text-green-800'
            } rounded-xl p-3 text-center text-base font-medium`}
          >
            {subMenu?.items?.map((item) => {
              return (
                <Link
                  style={{
                    ...decodeCssString(item.customisation.css as string),
                  }}
                  className="py-2 2xl:px-2 px-1.5 flex"
                  href={encoder(item)}
                >
                  {item.image ? (
                    <Image
                      className="object-contain mr-3"
                      src={item.image.url}
                      height={22}
                      width={22}
                      alt="menu icon"
                    />
                  ) : null}
                  <div>{item.name}</div>
                </Link>
              )
            })}
          </div>
        </>
      )}
    </>
  )
}

type NavigationProductsProps = {
  onBack: Function
  onSelect?: Function
  title: string
  items?: Models.MenuItems
  slug: string
  allTitle: string
  children?: ReactNode
}

const NavigationProducts = (props: NavigationProductsProps) => {
  const {
    onBack,
    onSelect,
    title,
    items = [],
    slug,
    allTitle,
    children,
  } = props
  let color = ''
  if (process.env.NEXT_PUBLIC_SITE_ID === '2') {
    color = 'text-blue-700'
  } else {
    color = 'text-green-700'
  }
  return (
    <>
      <div
        onClick={() => onBack()}
        className="flex items-center cursor-pointer px-4 py-3 font-medium mobile-nav-header-items"
      >
        <ArrowLeft weight="bold" className={clsx(color, 'mr-2  text-xl')} />
        <div className="text-xl items-center text-gray-900">{title}</div>
      </div>
      <div className="flex-1 overflow-y-auto mobile-nav-child-items">
        {/*Commented out to prevent auto generated mobile menu items from appearing*/}
        {/*{slug && (*/}
        {/*  <Link*/}
        {/*    href={slug}*/}
        {/*    className={clsx(*/}
        {/*      color,*/}
        {/*      'underline underline-offset-2 text-xl px-4 py-3 block font-medium',*/}
        {/*    )}*/}
        {/*  >*/}
        {/*    {allTitle}*/}
        {/*  </Link>*/}
        {/*)}*/}
        {items?.map((category) => (
          <>
            {category.type !== 'text:header' && (
              <NestedNavItem
                onSelect={() => {
                  if (onSelect) onSelect(category)
                }}
                item={category}
                key={category.id}
              />
            )}
          </>
        ))}
        {children}
      </div>
    </>
  )
}

type NavItemProp = {
  item: Models.MenuItem
  onSelect: () => any
  className?: string
}

const NavItem = (props: NavItemProp) => {
  const { item, className = '', onSelect } = props
  const cls = `w-full flex px-4 py-3 text-gray-900 cursor-pointer ${className}`
  const encode = useSlugEncoder()
  const child = (
    <div className="flex w-full justify-between text-left focus:outline-none text-xl items-center font-medium">
      <div className="flex items-center flex-1">
        <span>{item.name}</span>
      </div>
      {item.children.length > 0 && <CaretRight weight="bold" />}
    </div>
  )
  return !item.children.length ? (
    <Link
      style={{
        ...decodeCssString(item.customisation.mobilecss as string),
      }}
      href={encode(item)}
      className={cls}
    >
      {child}
    </Link>
  ) : (
    <div
      style={{
        ...decodeCssString(item.customisation.mobilecss as string),
      }}
      className={cls}
      onClick={() => onSelect && onSelect()}
    >
      {child}
    </div>
  )
}

const NestedNavItem = (props: NavItemProp) => {
  const { item, className, onSelect } = props
  const cls = `w-full flex px-4 py-3 ${className}`
  const encode = useSlugEncoder()
  const child = (
    <div className="text-white cursor-pointer flex flex-1">
      <div
        style={{
          ...decodeCssString(item.customisation.mobilecss as string),
        }}
        className="flex w-full text-left focus:outline-none text-xl items-center text-gray-500 "
      >
        <div className="flex flex-1 items-center">
          {/* <div className="h-10 w-10 mr-3 rounded-full bg-gray-200"></div> */}
          {item.slug ? (
            <Link
              onClick={(e) => {
                e.stopPropagation()
                return false
              }}
              href={encode(item)}
            >
              <span>{item.name}</span>
            </Link>
          ) : (
            <span>{item.name}</span>
          )}
        </div>
        {item.children.length > 0 && <CaretRight weight="bold" />}
      </div>
    </div>
  )
  return item.children.length == 0 ? (
    <Link className={cls} href={encode(item)}>
      {child}
    </Link>
  ) : (
    <div onClick={() => onSelect && onSelect()} className={cls}>
      {child}
    </div>
  )
}

const NavigationSidebar = ({
  show,
  menu,
  subMenu,
  contactNumber,
  emailAddress,
}: {
  show: boolean
  menu: Models.Menu
  subMenu?: Models.Menu
  contactNumber: string
  emailAddress?: string
}) => {
  const [selectionHistory, setSelectionHistory] = useState<number[]>([])
  const selectedCategory = useMemo<null | Models.MenuItem>(() => {
    if (selectionHistory.length === 0) return null
    const current = selectionHistory[selectionHistory.length - 1]
    return findProduct(current, menu.items)
  }, [selectionHistory])
  return (
    <div
      className={clsx(
        'mobile-navigation-container top-[65px] absolute bg-white w-full z-50 text-gray-900 transition-all duration-220 flex flex-col',
        show && 'flex left-0 ease-out flex-col',
        !show && '-left-[1000px] ease-in',
      )}
    >
      {selectionHistory.length == 0 && (
        <NavigationCategories
          menu={menu}
          subMenu={subMenu}
          contactNumber={contactNumber}
          emailAddress={emailAddress}
          onSelect={(category: NavigationLink) => {
            setSelectionHistory([...selectionHistory, category.id])
          }}
        />
      )}
      {selectionHistory.length > 0 && selectedCategory && (
        <NavigationProducts
          onSelect={(category: NavigationLink) => {
            setSelectionHistory([...selectionHistory, category.id])
          }}
          title={selectedCategory.name}
          allTitle={
            selectionHistory.length === 1 ? 'Shop All Categories' : 'Shop All'
          }
          items={selectedCategory?.children.filter(
            (item) => item.type != 'cta',
          )}
          slug={selectedCategory?.slug || ''}
          onBack={() => {
            const newHistory = [...selectionHistory]
            newHistory.pop()
            setSelectionHistory(newHistory)
          }}
        >
          <div className="flex flex-col">
            <Divider className="my-5" />
            <div className={'mega-menu-cta'}>
              {selectedCategory?.children
                //ctas will only render if there is an image  set
                .filter((item) => item.type === 'cta' && item.image)
                .map((item) => {
                  return (
                    <MenuItemCTA
                      item={item}
                      setActiveItem={() => {}}
                      key={item.id}
                    />
                  )
                })}
            </div>
          </div>
        </NavigationProducts>
      )}
    </div>
  )
}

export default NavigationSidebar
