'use client'
import CompareBottom from '@licommon/components/compares/CompareBottom'

const Banners = () => {
  return (
    <>
      <CompareBottom></CompareBottom>
    </>
  )
}

export default Banners
