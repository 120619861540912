'use client'

import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { Models } from '../../../../../../types/Models'
import { decodeCssString } from '../../../../../../utils'
import useSlugEncoder from "../../../hooks/useSlugEncoder"

import MenuItem = Models.MenuItem
type HeaderMegaMenuProps = {
  item: MenuItem
  setActiveItem?: (item: MenuItem, active: boolean) => void
}

const MenuItemLink = ({
  item,
  setActiveItem = () => {},
}: HeaderMegaMenuProps) => {
  const [active, setActive] = useState(false)
  const encodeSlug = useSlugEncoder()

  useEffect(() => {
    setActiveItem(item, active)
  }, [active])

  return (
    <div
      key={item.id}
      className={`mega-menu__item mega-menu__item_cta mr-2 2xl:mr-0 2xl:col-span-3 ${
        active ? 'mega-menu__item:active' : ''
      }`}
    >
      <Link href={encodeSlug(item)}
        onMouseEnter={(event) => setActive(true)}
        onMouseLeave={() => setActive(false)}
        className="dropdown-nav-item item-link text-green-800 w-100"
        data-children={item.id}
      >
        <div className={`flex justify-end mb-14 xl:mb-20 w-full`}>
          <div className="relative flex w-full h-[350px] 2xl:h-[400px] flex-1">
            {item.image ? (
                <Image
                    className="rounded-md w-full object-cover mega-menu__item_cta_image"
                    src={item.image.url}
                    height={800}
                    width={800}
                    alt={item.name}
                />
            ) : null}
            <div
                className="mega-menu__item_cta_content absolute -bottom-12 w-[90%] left-[5%] shadow-md text-sm xl:text-base rounded-md flex py-3 px-2 bg-white">
              <div className="flex flex-col pr-3">
                <div
                    className="text-gray-900 font-medium"
                    style={{
                      ...decodeCssString(item.customisation.css as string),
                    }}
                >
                  {item.name}
                </div>
                <div className="text-gray-600 font-light">
                  {item.subheading}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default MenuItemLink
