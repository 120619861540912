'use client'
import Link from 'next/link'
import { LegacyRef, ReactNode, forwardRef } from 'react'
import { clsx } from '../../../../../utils'
import { getPortalType } from '../../../../../utils/portal'

export const MenuItem = forwardRef(
  (
    props: {
      icon: ReactNode
      text: string
      id?: string
      className?: string
      active?: boolean
      onMouseEnter?: Function
      onClick?: Function
      children?: ReactNode
      link?: string
      href?: string
    },
    ref,
  ) => {
    const {
      icon,
      className = '',
      text,
      active = false,
      id = '',
      onMouseEnter = () => {},
      onClick = () => {},
      children,
      link,
      href,
    } = props
    const cls = clsx(
      'menu-item',
      !active && 'text-white',
      active && 'active bg-white text-gray-900',
      className,
    )
    const { isCFD } = getPortalType()

    const navChild = (
      <>
        <div className="text-[1.75rem]">{icon}</div>
        <div
          className={clsx(
            isCFD && 'lg:block hidden',
            'lg:text-xs text-[.8rem] mt-1',
          )}
        >
          {text}
        </div>
      </>
    )

    const Component = href ? 'a' : 'div'

    return (
      <div className="relative" ref={ref as LegacyRef<HTMLDivElement>}>
        {!link ? (
          <Component
            id={id}
            onMouseEnter={() => onMouseEnter()}
            onClick={() => onClick()}
            className={cls}
            {...(href ? { href } : {})}
          >
            {navChild}
          </Component>
        ) : (
          <Link href={link} className={cls}>
            {navChild}
          </Link>
        )}
        {children}
      </div>
    )
  },
)
