'use client'
import { Popover } from '@headlessui/react'
import { useAppSelector } from '@licommon/hooks/store/hooks'
import { Fragment, MutableRefObject, useRef } from 'react'
import useOnClickOutside from '../hooks/useOnClickOutside'
import { formatCurrency } from '../utils/currency'
import { getPortalType } from '../utils/portal'
import Badge from './Badge'
import Button from './Button'
import { CartItemPreview } from './CartItemPreview'
import Divider from './Divider'
import WithTransition from './hoc/WithTransition'

type ViewCartModalProps = {
  show: boolean
  onClose?: Function
  containerRef?: MutableRefObject<any>
  className?: string
  viewCartVariant?: 'li' | 'cfd'
}

const WithModal = (props: ViewCartModalProps) => {
  const {
    show,
    onClose = () => {},
    containerRef,
    className,
    viewCartVariant = 'li',
  } = props
  const ref = useRef(null)
  useOnClickOutside(containerRef || ref, onClose)
  return (
    <div ref={containerRef || ref} onMouseLeave={() => onClose()}>
      <Popover as={Fragment}>
        <WithTransition
          show={show}
          className={`absolute z-50 overflow-hidden top-[76px] right-0 border border-gray-300 w-[400px] bg-white text-black border-t-0 md:rounded-b-md ${className}`}
        >
          <Popover.Panel static className="flex-1 flex">
            <WithoutModal viewCartVariant={viewCartVariant} />
          </Popover.Panel>
        </WithTransition>
      </Popover>
    </div>
  )
}

const WithoutModal = ({
  viewCartVariant,
}: {
  viewCartVariant?: 'li' | 'cfd'
}) => {
  const items = useAppSelector((state) => state.cart.products)
  const { isCFD } = getPortalType()

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <div className="flex flex-1 flex-col overflow-y-auto md:max-h-[50vh]">
        {items.map((item) => (
          <div key={item.name} className="p-3 flex flex-col">
            <CartItemPreview editable={true} product={item} />
          </div>
        ))}
        {items.length == 0 && (
          <div className="p-3 font-medium text-center">
            No items in cart yet!
          </div>
        )}
      </div>

      <div className="flex-col hidden md:flex">
        <Divider className="hidden md:block" />
        <SubTotal className="hidden md:flex" />
        <div className="flex items-end mt-3">
          <Button
            href="/cart"
            className="flex-1 justify-center items-center m-3 mt-0 mr-1"
            variant="secondary"
          >
            <span>View Cart</span>
          </Button>
          <Button
            href={!items.some(item => !item.isAvailable) ? "/checkout" : undefined}
            className="flex-1 justify-center items-center m-3 mt-0 ml-1"
            variant={isCFD ? 'blue' : 'success'}
            disabled={items.find(item => !item.isAvailable)}
          >
            <span>Checkout</span>
          </Button>
        </div>
      </div>

      {/* for mobile view */}
      <div className="flex flex-col md:hidden justify-end">
        <Divider />
        <SubTotal />
        <Divider />
        <div className="flex items-end mt-3">
          <Button
            href="/cart"
            className="flex-1 justify-center items-center m-3 mt-0 mr-1"
            variant="secondary"
          >
            <span>View Cart</span>
          </Button>
          <Button
            href={!items.some(item => !item.isAvailable) ? "/checkout" : undefined}
            className="flex-1 justify-center items-center m-3 mt-0 ml-1"
            variant={isCFD ? 'blue' : 'success'}
            disabled={items.find(item => !item.isAvailable)}
          >
            <span>Checkout</span>
          </Button>
        </div>
      </div>
      {/* for mobile view */}
    </div>
  )
}

const SubTotal = ({ className = '' }: { className?: string }) => {
  const items = useAppSelector((state) => state.cart.products)
  const { isCFD } = getPortalType()
  const total = useAppSelector((state) => state.cart.subTotal)
  const shippingCost = useAppSelector((state) => state.cart.shippingAmount)
  const totalDiscount = items.reduce((sum, item) => sum + item.totalDiscount, 0)
  return (
    <div className={`flex p-3 ${className}`}>
      <div className="flex flex-col flex-1 gap-3">
        <div className="flex justify-between">
          <div className="text-sm text-left font-medium">
            Subtotal
            <div className="text-sm text-[0.875rem] text-gray-500">
              excluding delivery
            </div>
          </div>
          <div className="text-gray-900 text-[0.875rem] font-semibold">
            {formatCurrency(total)}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm text-left">Total Saving</div>
          <div className="text-red-600 text-[0.875rem] font-semibold">
            {formatCurrency(totalDiscount)}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm text-left">Estimated Shipping</div>
          <div className="text-gray-900 text-[0.875rem] font-semibold">
            {shippingCost == 0 && items.length ? (
              <Badge
                className={`${
                  isCFD ? 'bg-yellow-500 text-gray' : 'bg-green-700'
                }`}
              >
                Free Shipping
              </Badge>
            ) : (
              <span className="text-grey-600">
                {formatCurrency(shippingCost)}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
const ViewCartPreview = { Modal: WithModal, NoModal: WithoutModal }
export default ViewCartPreview
