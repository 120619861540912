import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/authSlice'
import cartReducer from './features/cartSlice'
import checkoutReducer from './features/checkoutSlice'
import wishlistReducer from './features/wishlistSlice'
import wishlistPopupReducer from './features/popupWishlistSlice'
import compareReducer from './features/compareSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    wishlist: wishlistReducer,
    wishlistPopup: wishlistPopupReducer,
    compare: compareReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
