import { useRef, useState } from 'react'
import { MagnifyingGlass, X } from '../../../../Icons'
import HeaderSearchModal from '../../components/HeaderSearchModal'
import { SEARCH_PLACEHOLDER } from '../../desktop/components/SearchBar'
import useGlobalSearch from '../../hooks/useGlobalSearch'

type NavigationSearchProps = {
  onClose: Function
}

const NavigationSearch = (props: NavigationSearchProps) => {
  const { onClose } = props
  const [search, setSearch] = useState('')
  const ref = useRef(null)

  const { products, categories, loading, debouncedSearch } =
    useGlobalSearch(search)

  return (
    <div
      ref={ref}
      className="absolute h-screen max-h-screen w-screen top-0 left-0 text-blue-700 bg-white z-100"
    >
      <div className="p-3 header-topbar flex items-center border-b border-gray-300">
        <div className="flex items-center text-[1.45rem] flex-1">
          <MagnifyingGlass weight="bold" className="flex-shrink-0" />
          <div className="mx-3 flex-1">
            <input
              autoFocus={true}
              placeholder={SEARCH_PLACEHOLDER}
              className="border border-gray-300 focus:border-primary rounded-md bg-transparent w-full"
              value={search}
              style={{ outline: 'none' }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <X
            weight="bold"
            onClick={() => onClose()}
            className="flex-shrink-0"
          />
        </div>
      </div>
      <HeaderSearchModal
        show={true}
        containerRef={ref}
        products={products}
        categories={categories}
        searchTerm={debouncedSearch}
        onClose={() => {}}
        loading={loading}
      />
    </div>
  )
}
export default NavigationSearch
