"use client";
import { Popover } from "@headlessui/react";
import { clsx } from "@licommon/utils/clsx";
import { Fragment, MutableRefObject, useRef } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { getPortalType } from "../utils/portal";
import Button from "./Button";
import Divider from "./Divider";
import WithTransition from "./hoc/WithTransition";

type DoAuthModalProps = {
  show: boolean;
  onClose?: Function;
  containerRef?: MutableRefObject<any>;
  className?: string;
};

const DoAuthModal = (props: DoAuthModalProps) => {
  const { show, onClose = () => {}, containerRef, className = "" } = props;
  const ref = useRef(null);
  useOnClickOutside(containerRef || ref, onClose);
  return (
    <div ref={containerRef || ref} onMouseLeave={() => {onClose()}}>
      <Popover as={Fragment}>
        <WithTransition
          show={show}
          className={`absolute z-50 overflow-hidden top-[76px] -left-[157px] md:border border-gray-300 w-[314px] bg-white text-gray-900 md:border-t-0 md:rounded-b-md ${className}`}
        >
          <Popover.Panel static className="flex-1 flex">
            <NoModal {...props} />
          </Popover.Panel>
        </WithTransition>
      </Popover>
    </div>
  );
};

type DoAuthNoModalProps = {
  onClose?: Function;
};
const NoModal = (props: DoAuthNoModalProps) => {
  const { onClose = () => {} } = props;
  const { isCFD } = getPortalType();

  return (
    <div className="flex flex-col">
      <div className="flex p-3 px-4 flex-col">
        <div className="text-xl font-medium">Login</div>
        <div className="text-sm text-gray-600">
          Sign in to your account and enjoy a seamless shopping experience
        </div>
        <Button
          href="/auth/login"
          variant={isCFD ? "blue" : "success"}
          onClick={onClose}
          className={clsx("flex justify-center mt-2", isCFD && "rounded-lg")}
        >
          Login
        </Button>
      </div>
      <Divider />
      <div className="flex p-3 px-4 flex-col">
        <div className="text-xl font-medium">Create an Account</div>
        <div className="text-sm text-gray-600">
          Join our community and get exclusive access to promotions, deals, and
          more!
        </div>
        <Button
          href="/auth/register"
          variant={isCFD ? "blue" : "success"}
          onClick={onClose}
          className={clsx("flex justify-center mt-2", isCFD && "rounded-lg")}
        >
          Create An Account
        </Button>
      </div>
      <Divider className="md:hidden" />
    </div>
  );
};

const DoAuthPreview = {
  Modal: DoAuthModal,
  NoModal: NoModal,
};

export default DoAuthPreview;
