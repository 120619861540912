'use client'
import useAddToCart from '@licommon/hooks/cart/useAddToCart'
import { formatCurrency } from '@licommon/utils/currency'
import { truncate } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { getPortalType } from '../utils/portal'
import Button from './Button'
import { ShoppingCart, Trash } from './Icons'
import { TProductCard } from './product-card'

export type WishlistItemPreviewProps = {
  product: TProductCard
  handleRemoveFromWishlist: (productId: number) => void
}

export const CartItemPreview = (props: WishlistItemPreviewProps) => {
  const { handleRemoveFromWishlist, product } = props
  const toggleWishlist = (productId: number) => {
    handleRemoveFromWishlist(productId)
  }
  const { isCFD } = getPortalType()

  const { addToCart, loading: isAdding } = useAddToCart()

  return (
    <>
      <div className="flex">
        <Image
          src={product.thumbnail.url}
          height={80}
          width={80}
          alt="image"
          priority={true}
          loading="eager"
          className="h-[80px] w-[80px]"
        />
        <div className="flex flex-col ml-2 flex-1">
          <Link
            href={`/product/${product.slug}`}
            className={`${
              isCFD ? 'text-blue-700' : 'text-green-800'
            } text-sm underline text-left font-medium`}
          >
            {truncate(`${product.name}`, {
              length: 60,
            })}
          </Link>
        </div>
        <div className="text-gray-900 text-[0.875rem] font-semibold">
          {formatCurrency(product.sale_price)}
        </div>
        {/* <X
          className="ml-3 cursor-pointer"
          weight="bold"
          size={20}
          onClick={() => {
            toggleWishlist(product.id)
          }}
        /> */}
      </div>
      <div className="flex gap-2 pb-3">
        <Button
          onClick={() => {
            toggleWishlist(product.id)
          }}
          className="w-1/2 justify-center gap-2"
        >
          <Trash width={20} height={20}></Trash>Remove
        </Button>
        <Button
          onClick={() => [addToCart(product?.id, 1)]}
          className="bg-primary hover:bg-primary w-1/2 justify-center gap-2 text-white border-none"
        >
          <ShoppingCart width={20} height={20}></ShoppingCart> Add to Cart
        </Button>
      </div>
    </>
  )
}
export default CartItemPreview
