'use client'
import { usePathname } from 'next/navigation'
import { ReactNode, useEffect, useState } from 'react'
import { Models } from '../../../../types/Models'
import NavigationSearch from './components/NavigationSearch'
import NavigationSidebar from './components/NavigationSidebar'
import NavigationTopBar from './components/NavigationTopBar'

const HeaderMobileNavigation = ({
  menu,
  subMenu,
  contactNumber,
  showStore = false,
  emailAddress,
}: {
  logo?: ReactNode
  menu: Models.Menu
  subMenu?: Models.Menu
  contactNumber: string
  showStore?: boolean
  emailAddress?: string
}) => {
  const [showSidebar, setShowSidebar] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const path = usePathname()
  useEffect(() => {
    setShowSidebar(false)
    setShowSearch(false)
  }, [path])
  return (
    <>
      <NavigationTopBar
        showSidebar={showSidebar}
        showStore={showStore}
        onCloseSidebar={() => setShowSidebar(false)}
        onOpenSidebar={() => setShowSidebar(true)}
        onShowSearch={() => setShowSearch(true)}
      >
        <NavigationSidebar
          contactNumber={contactNumber}
          emailAddress={emailAddress}
          subMenu={subMenu}
          menu={menu}
          show={showSidebar}
        />
      </NavigationTopBar>
      {showSearch && <NavigationSearch onClose={() => setShowSearch(false)} />}
    </>
  )
}
export default HeaderMobileNavigation
