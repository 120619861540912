'use client'
import { useAppSelector } from "@licommon/hooks/store";
import { setCompareProducts } from "@licommon/hooks/store/features/compareSlice";
import { useDispatch } from "react-redux";
import { alertError, alertSuccess, showError } from "../../utils/alert";
import useFetch from "../useFetch";
import { ProductCompareResponse } from './../../types/Product';



export type Product = {
  id:number,
  name:string,
  slug:string,
  img:string,
}

export const COMPARE_ITEMS_LIMIT = 4

const useCompare = ()=>{

  const compareItems = useAppSelector(state=>state.compare.products)
  const dispatch = useDispatch()
  const {loading, fetch:fetchComparisonData} = useFetch<ProductCompareResponse[]>('api.get-compare-products', {}, false, {cache:'force-cache'})

  const addToCompare = (product: Product)=>{
    if(compareItems.some(item=>item.id==product.id)) return alertError('Product already in compare')
    if(compareItems.length==COMPARE_ITEMS_LIMIT) return showError(`You can only compare ${COMPARE_ITEMS_LIMIT} products at a time`)
    const newCompareItems = [...compareItems, product]
    dispatch(setCompareProducts(newCompareItems))
    return alertSuccess('Product added to compare')
  }

  const removeFromCompare = (product: Product)=>{
    const newCompareItems = compareItems.filter(item=>item.id!=product.id)
    dispatch(setCompareProducts(newCompareItems))
    return alertSuccess('Product removed from compare')
  }

  const isProductInCompare = (product: Product)=>{
    return compareItems.some(item=>item.id==product.id)
  }

  const clearCompare = ()=>{
    if(compareItems.length===0) return 
    dispatch(setCompareProducts([]))
    return alertSuccess('Compare cleared')
  }

  const getComparisonData = () =>{
    const currentComparisonSlugs = compareItems.map(item=>item.slug)
    return fetchComparisonData({product_slugs:currentComparisonSlugs})
  }

  return {addToCompare,compareItems, removeFromCompare, isProductInCompare, clearCompare, getComparisonData, loading}
}

export default useCompare