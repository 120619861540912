'use client'

import { decodeCssString } from '@licommon/utils/css'
import Image from 'next/image'
import { CSSProperties } from 'react'
import { Models } from '../../../../../../types/Models'
import MenuItem from './MenuItem'

type HeaderMegaMenuProps = {
  item: Models.MenuItem
  name: string
  classNames?: string
  styles?: CSSProperties
  children?: React.ReactNode
}

const MenuItemHeader = ({
  name,
  item,
  children,
  styles,
  classNames,
}: HeaderMegaMenuProps) => {
  return (
    <MenuItem item={item} styles={styles} classNames={classNames}>
      <div
        className={`mega-menu__item dropdown-nav-row flex-1 2xl:flex-none mb-2`}
        style={{
          ...decodeCssString(item.customisation.css as string),
        }}
      >
        {item.image ? (
          <Image
            className="object-contain mr-3"
            src={item.image.url}
            height={30}
            width={30}
            alt="menu icon"
          />
        ) : null}
        <h2 className={'item-header'}>{name}</h2>
      </div>

      <div className={`item__children`}>{children}</div>
    </MenuItem>
  )
}

export default MenuItemHeader
